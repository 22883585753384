import React, { Component, Redirect } from "react";
import Modal from "@material-ui/core/Modal";
import Box from '@material-ui/core/Box';
import Card from "../../general/components/theme-components/Card/Card";
import CardHeader from "../../general/components/theme-components/Card/CardHeader";
import CardBody from "../../general/components/theme-components/Card/CardBody";
import CardFooter from "../../general/components/theme-components/Card/CardFooter";
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from "../../general/components/theme-components/CustomButtons/Button.js";

import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import { ToastsStore } from "react-toasts";
import { getBookDetail, getBooksForSwap, createRequest, getProfileDetails } from "../../../http/http-calls";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

class ViewDetailModal extends Component {

	state = {
		bookId: "",
		book: [],
		user: {},
		swapAction: {
			isOpen: false,
			swapBooks: [],
			selectedBook: ""
		},
		selectedImage: "",
		userId: "",
	}

	componentDidUpdate(prevProps) {
		if (this.props.isOpen !== prevProps.isOpen) {
			if (this.props.creatorData !== null) {
				this.setState({
					bookId: this.props.creatorData
				}, () => {
					this._getBookAllDetails()
					this._getMyProfile()
				});
			}
		}
	}

	_getBookAllDetails = () => {
		let { bookId, book, user, selectedImage } = this.state;
		this.props.showLoader();
		getBookDetail(bookId).then((resp) => {
			// console.log("Book details response here :- ", resp);
			book = resp.book;
			user = resp.user;
			selectedImage = resp.book.imageUrl[0];
			this.setState({ book, user, selectedImage });
			this.props.hideLoader();
		}).catch((err) => {
			this.props.hideLoader();
			ToastsStore.error("Failed to load book details :- " + err.reason);
		});
	}

	_getMyProfile = () => {
		getProfileDetails().then((resp) => {
			this.setState({
				userId: resp.user._id
			});
		}).catch((err) => {
			ToastsStore.error("Failed to load logged-in user details :- " + err.reason);
		});
	}

	_closeModal = () => {
		this.setState({
			swapAction: {
				isOpen: false,
				swapBooks: [],
				selectedBook: ""
			}
		}, () => {
			this.props.toggle(null);
			this.props.reload();
		});
	}

	_handleUserBookshelf = (user) => {
		this._closeModal();
		if (user.name.first === this.props.userData?.name) {
			window.open("/my-book-shelf", "_self");
		} else {
			this.props.toUserBookShelf(user._id);
		}
	}

	// _handleAction = () => {
	// 	const { action, swapAction, book, userId } = this.state;
	// 	if (book.bookStatusMode === "Both") {
	// 		action.isOpen = true;
	// 		swapAction.isOpen = false;
	// 		this.setState({ action, swapAction });
	// 	} else if (book.bookStatusMode === "Swap") {
	// 		swapAction.isOpen = true;
	// 		action.isOpen = false;
	// 		getBooksForSwap(userId).then((resp) => {
	// 			console.log("Books for swapping here :- ", resp);
	// 			swapAction.swapBooks = resp.books;
	// 			this.setState({ swapAction, action });
	// 		}).catch((err) => {
	// 			ToastsStore.error("Failed to load books :- " + err.reason);
	// 		});
	// 	} else if (book.bookStatusMode === "Sell") {
	// 		const payload = {
	// 			isSwap: false,
	// 			requestedBook: book._id
	// 		};
	// 		this.props.showLoader();
	// 		createRequest(payload).then((resp) => {
	// 			console.log("Request response here :- ", resp);
	// 			ToastsStore.success("Request sent successfully");
	// 			this.props.hideLoader();
	// 			this._closeModal();
	// 		}).catch((err) => {
	// 			this.props.hideLoader();
	// 			ToastsStore.error("Failed to send request :- " + err.reason);
	// 		});
	// 	}
	// }

	_sellRequest = () => {
		const { book } = this.state;
		const payload = {
			isSwap: false,
			requestedBook: book._id
		};
		this.props.showLoader();
		createRequest(payload).then((resp) => {
			// console.log("Request response here :- ", resp);
			ToastsStore.success("Request sent successfully");
			this.props.hideLoader();
			this._closeModal();
		}).catch((err) => {
			this.props.hideLoader();
			ToastsStore.error("Failed to send request :- " + err.reason);
		});
	}

	_swapRequest = () => {
		const { swapAction, userId } = this.state;
		swapAction.isOpen = true;
		getBooksForSwap(userId).then((resp) => {
			// console.log("Books for swapping here :- ", resp);
			swapAction.swapBooks = resp.books;
			this.setState({ swapAction });
		}).catch((err) => {
			ToastsStore.error("Failed to load books :- " + err.reason);
		});
	}

	_handleSelectedBook = (swapBook) => {
		const { book } = this.state;
		const payload = {
			isSwap: true,
			requestedBook: book._id,
			swapBook
		};
		this.props.showLoader();
		createRequest(payload).then((resp) => {
			// console.log("Request response here :- ", resp);
			ToastsStore.success("Request sent successfully");
			this.props.hideLoader();
			this._closeModal();
		}).catch((err) => {
			this.props.hideLoader();
			ToastsStore.error("Failed to send request :- " + err.reason);
		});
	}

	_handleType = (value) => {
		const { action, swapAction, book, userId } = this.state;
		action.type = value;
		if (value === "Swap") {
			swapAction.isOpen = true;
			getBooksForSwap(userId).then((resp) => {
				// console.log("Books for swapping here :- ", resp);
				swapAction.swapBooks = resp.books;
				this.setState({ swapAction, action });
			}).catch((err) => {
				ToastsStore.error("Failed to load books :- " + err.reason);
			});
		} else if (value === "Sell") {
			const payload = {
				isSwap: false,
				requestedBook: book._id
			};
			this.props.showLoader();
			createRequest(payload).then((resp) => {
				// console.log("Request response here :- ", resp);
				ToastsStore.success("Request sent successfully");
				this.props.hideLoader();
				this._closeModal();
			}).catch((err) => {
				this.props.hideLoader();
				ToastsStore.error("Failed to send request :- " + err.reason);
			});
		}
	}

	_handleImages = (image) => {
		let { selectedImage } = this.state;
		selectedImage = image;
		this.setState({ selectedImage });
	}

	_showBook = () => {
		const { book, user, swapAction, selectedImage } = this.state;

		return book && (
			<div className="MyBookShelfModal">
				<div className="modalBody view-details-page">
					<Card className="bookShellCard bookShellModal">
						<CardHeader className="card-header">
							<Grid direction="row" justify="center" alignItems="center" className="bookShellCard">
								<GridItem
									xs={6}
									spacing={1}
									className="bookShellCard-grid-item card-header-left"
								>
									<h3 className="card-heading fw-700 fs-16">{book?.title}</h3>
									<h4 className="view-category fs-14 text-theme m-0 fw-500">Category: {book?.category?.length && book.category.toString()} </h4>
									<h4 className="view-category fs-14 text-theme m-0 fw-500">Genre: {book?.topic && book.topic} </h4>
								</GridItem>
								<GridItem
									xs={6}
									spacing={1}
									className="bookShellCard-grid-item card-header-right"
								>
									<h4 className="view-category fs-14 m-0 fw-500">Condition: {book?.condition} </h4>
									<Link className="view-category fs-14 text-theme fw-500 m-0"
										onClick={() => this._handleUserBookshelf(user)}
									>Owner : {user?.username && user.username}</Link>
									<h4 className="view-category fs-14 text-theme m-0 fw-500">Language: {book?.language && book.language} </h4>
								</GridItem>
							</Grid>
						</CardHeader>
						<CardBody className="card-body w-100 h-100">
							<Grid direction="row" justify="center" className="bookShellCard">
								<GridItem
									xs={12}
									lg={6}
									spacing={1}
									className="bookShellCard-grid-item  card-body-left"
									style={{ padding: "0px !important" }}
								>
									<Box component="div" className="ViewDetialLeft">
										<Box component="div" className="ViewDetialsFullImg">
											<img
												src={selectedImage}
												alt="Book Img"
												className="fit-img"
											/>
										</Box>

										<Box component="div" className="ViewDetialImgList">
											<List component="nav">
												{book?.imageUrl?.map((image, index) => {
													return (
														<ListItem className="view-details-img-item" key={index}
															onClick={() => this._handleImages(image)}
														>
															<img
																src={image}
																alt="Book Img"
																className="fit-img"
																style={{ objectFit: 'contain' }}
															/>
														</ListItem>
													)
												})}
											</List>
										</Box>
									</Box>
								</GridItem>
								<GridItem
									xs={12}
									lg={6}
									spacing={1}
									className="bookShellCard-grid-item  card-body-right"
									style={{ padding: "0px !important" }}
								>
									<Box component="div" className="ViewDetialRight">
										<Box component="div" className="price-location">
											{book.bookStatusMode === "Sell" || book.bookStatusMode === "Both" ? <span className="view-price fs-24 fw-600">{`₹${book.price}`}</span>
												: <></>
											}
											<span className="view-location fs-16 fw-500">{book.city}</span>
										</Box>

										<Box component="div" className="view-details-text">
											<h3 className="view-details-parahead fs-16 fw-600">Description</h3>
											<p className="view-details-para fs-14 fw-500">
												{book?.description}
											</p>
										</Box>
										{book.bookStatusMode !== "Proud Owner" &&
											(
												book._isRequested === 0 ? (
													<Box component="div" className="card-button-container">
														{book?.bookStatusMode === "Both" ?
															<>
																<Button className="swap" round
																	onClick={() => this._swapRequest()}
																>
																	Swap
																</Button>
																<Button className="sale" round
																	onClick={() => this._sellRequest()}
																>
																	Buy
																</Button>
															</>
															: book.bookStatusMode === "Swap" ?
																<Button className="swap" round
																	onClick={() => this._swapRequest()}
																>
																	Swap
																</Button>
																: <Button className="sale" round
																	onClick={() => this._sellRequest()}
																>
																	Buy
																</Button>
														}
													</Box>
												) : (
													<h6 style={{ color: '#f16667', textAlign: "center" }}>
														You requested for this book. See <Link to="/my-requests">Requests</Link>
													</h6>
												)
											)
											// book._isRequested === 0 ? (
											// 	<Box component="div" className="card-button-container">
											// 		{book?.bookStatusMode === "Both" ?
											// 			<>
											// 				<Button className="swap" round
											// 					onClick={() => this._swapRequest()}
											// 				>
											// 					Swap
											// 				</Button>
											// 				<Button className="sale" round
											// 					onClick={() => this._sellRequest()}
											// 				>
											// 					Buy
											// 				</Button>
											// 			</>
											// 			: book.bookStatusMode === "Swap" ?
											// 				<Button className="swap" round
											// 					onClick={() => this._swapRequest()}
											// 				>
											// 					Swap
											// 				</Button>
											// 				: <Button className="sale" round
											// 					onClick={() => this._sellRequest()}
											// 				>
											// 					Buy
											// 				</Button>
											// 		}
											// 	</Box>
											// ) : (
											// 	<h6 style={{ color: '#f16667', textAlign: "center" }}>
											// 		You requested for this book. See <Link to="/my-requests">Requests</Link>
											// 	</h6>
											// )
										}
										{/* {book._isRequested === 0 ? (
											<Box component="div" className="card-button-container">
												{book?.bookStatusMode === "Both" ?
													<>
														<Button className="swap" round
															onClick={() => this._swapRequest()}
														>
															Swap
														</Button>
														<Button className="sale" round
															onClick={() => this._sellRequest()}
														>
															Buy
														</Button>
													</>
													: book.bookStatusMode === "Swap" ?
														<Button className="swap" round
															onClick={() => this._swapRequest()}
														>
															Swap
														</Button>
														: <Button className="sale" round
															onClick={() => this._sellRequest()}
														>
															Buy
														</Button>
												}
											</Box>
										) : (
											<h6 style={{ color: '#f16667', textAlign: "center" }}>
												You requested for this book. See <Link to="/my-requests">Requests</Link>
											</h6>
										)
										} */}

										{/* {action.isOpen
											&& <Box>
												<FormControl variant="outlined" className="m-0">
													<InputLabel id="demo-simple-select-autowidth-label2">Type</InputLabel>
													<Select
														labelId="demo-simple-select-autowidth-label2"
														label="All Type"
														value={action.type}
														onChange={(e) => this._handleType(e.target.value)}
													>
														<MenuItem>
															<em>All</em>
														</MenuItem>
														<MenuItem value="Swap">Swap</MenuItem>
														<MenuItem value="Sell">Sell</MenuItem>
													</Select>
												</FormControl>
											</Box>
										} */}

										{swapAction.isOpen
											&& (swapAction.swapBooks?.length > 0
												? <Box style={{ paddingTop: "20px" }}>
													<FormControl variant="outlined" className="m-0">
														<InputLabel id="demo-simple-select-autowidth-label2">Books for Swap</InputLabel>
														<Select
															labelId="demo-simple-select-autowidth-label2"
															label="All Type"
															value={swapAction.selectedBook}
															onChange={(e) => this._handleSelectedBook(e.target.value)}
														>
															{/* <MenuItem>
																<em>All</em>
															</MenuItem> */}
															{swapAction.swapBooks?.length
																&& swapAction.swapBooks.map((book) => {
																	return (
																		<MenuItem value={book._id}>{book.title}</MenuItem>
																	)
																})
															}
														</Select>
													</FormControl>
												</Box>
												: <h6 style={{ color: '#f16667', textAlign: "center" }}>
													You do not have any book in your bookshelf to perform a swap. Please add a book in your <Link to="/my-book-shelf">bookshelf</Link>
												</h6>
											)
										}
									</Box>
								</GridItem>
							</Grid>
						</CardBody>
						<CardFooter style={{ justifyContent: 'flex-end' }}>
							<Button round onClick={() => this._closeModal()}>
								Close
							</Button>
						</CardFooter>
					</Card>
				</div>
			</div>
		)
	}

	render() {
		const { book } = this.state;

		return (
			<Modal
				open={this.props.isOpen}
				onClose={() => this._closeModal()}
			>
				{book && this._showBook()}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		loaderData: state.loaderData,
		userData: state.userData
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoader: (loaderData) => dispatch(showLoader(loaderData)),
		hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDetailModal);