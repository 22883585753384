import React from "react";
import ReactDOM from "react-dom";

//theme css
import "./assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

// custom css
import "./index.css";
import "./App.scss";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
