import React, { Component } from "react";
import moment from "moment";
import CustomDateRangePicker from "../../general/components/dateRangePicker";

// material-ui components
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import Box from '@material-ui/core/Box';
import Card from "../../general/components/theme-components/Card/Card";
import CardBody from "../../general/components/theme-components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import UserBookShelf from "./UserBookShelf";
import ViewDetailModal from "./ViewDetailModal";
import MyRequestsRecieved from "./MyRequestsRecieved";
import { getRequestsByUser, getRequest } from "../../../http/http-calls";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { ToastsStore } from "react-toasts";
import Paginate from "../../general/components/pagination";
import { Chat } from "./Chat";
import { Link } from "@material-ui/core";

class MyRequests extends Component {

  state = {
    viewDetailModal: {
      isOpen: false,
      data: null,
    },
    search: "",
    filters: {
      interest: "All",
      category: "All",
      dateRange: {
        startDate: null,
        endDate: null,
        focusedInput: null,
        startDateId: "startDate",
        endDateId: "endDate"
      }
    },
    categories: [],
    requests: [],
    requestsType: {
      sent: true,
      received: false
    },
    requestsTypeBackup: {
      sent: false,
      received: false
    },
    totalPages: "",
    currentPage: 1,
    totalCount: "",
    chatBox: {
      isOpen: false,
      request: null,
    },
    userBookShelf: {
      isOpen: false,
      userId: null
    },
  }

  componentDidMount() {
    if (this.props?.location?.query?.title !== undefined
      || this.props?.location?.search !== ""
    ) {
      if (this.props.location?.query?.title === "New Request"
        || this.props?.location?.search.includes("received")
      ) {
        this.setState({
          requestsType: {
            sent: false,
            received: true
          }
        });
      } else if (this.props?.location?.search.includes("requestId")) {
        const request = this.props.location.search.split("=")[1]
        this._startChat(request);
      }
    }
    this._getRequestSent();
  }

  _getRequestSent = () => {
    this.props.showLoader();
    const { search, filters } = this.state;
    const payload = {
      filters: {},
      pageNumber: this.state.currentPage
    }
    if (search !== undefined && search !== "") {
      payload.search = search
    }
    if (filters.interest !== undefined && filters.interest !== "" && filters.interest !== "All") {
      payload.filters.interest = filters.interest
    }
    if (filters.category !== undefined && filters.category !== "" && filters.category !== "All") {
      payload.filters.category = filters.category
    }
    if (filters.dateRange.startDate && filters.dateRange.endDate) {
      payload.filters.dateRange = {
        from: moment(filters.dateRange.startDate).format('DD/MM/YYYY'),
        to: moment(filters.dateRange.endDate).format('DD/MM/YYYY')
      }
    }
    getRequestsByUser(payload).then((resp) => {
      console.log("List of Request sent here :- ", resp);
      this.setState({
        requests: resp.requests,
        categories: resp.setting.categories,
        currentPage: resp.currentPage,
        totalPages: resp.totalPages,
        totalCount: resp.totalCount,
      });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Failed to load requests sent :- " + err.reason);
    });
  }

  _onToggleViewDetailModal = (data) => {
    let { viewDetailModal } = JSON.parse(JSON.stringify(this.state));
    viewDetailModal.isOpen = !viewDetailModal.isOpen;
    if (data !== undefined) {
      viewDetailModal.data = data;
    } else {
      viewDetailModal.data = null;
    }
    this.setState({
      viewDetailModal,
    });
  }

  _toggleRequestsTypes = (value) => {
    const { requestsType } = this.state;
    if (value === "Sent") {
      requestsType.sent = true;
      requestsType.received = false;
    } else {
      requestsType.sent = false;
      requestsType.received = true;
    }
    this.setState({ requestsType });
  }

  _handleUserBookShelf = (id) => {
    const { requestsType, requestsTypeBackup } = this.state;
    requestsTypeBackup.sent = requestsType.sent;
    requestsTypeBackup.received = requestsType.received;
    this.setState({
      userBookShelf: {
        isOpen: true,
        userId: id
      },
      requestsType: {
        sent: false,
        received: false
      },
      requestsTypeBackup
    });
  }

  _closeUserBookShelf = () => {
    const { requestsType, requestsTypeBackup } = this.state;
    requestsType.sent = requestsTypeBackup.sent;
    requestsType.received = requestsTypeBackup.received;
    this.setState({
      userBookShelf: {
        isOpen: false,
        userId: null
      },
      requestsTypeBackup: {
        sent: false,
        received: false
      },
      viewDetailModal: {
        isOpen: false,
        data: null,
      },
      requestsType
    });
  }

  _clearSuggestions = () => {
    this.setState({
      search: "",
      filters: {
        interest: "All",
        category: "All",
        dateRange: {
          startDate: null,
          endDate: null,
          focusedInput: null,
          startDateId: "startDate",
          endDateId: "endDate"
        }
      }
    }, () => {
      this._getRequestSent()
    });
  }

  _handleOnChange = (field, value) => {
    let { search, filters } = this.state;
    if (field === "search") {
      search = value;
      this.setState({ search }, () => {
        this._getRequestSent()
      });
    } else {
      if (value !== undefined) {
        filters[field] = value;
      } else {
        filters[field] = "";
      }
      this.setState({ filters }, () => {
        this._getRequestSent();
      });
    }
  }

  _onDatesChange = (startDate, endDate) => {
    const { filters } = this.state;
    filters.dateRange.startDate = startDate;
    filters.dateRange.endDate = endDate;
    this.setState({ filters });
    if (filters.dateRange.startDate && filters.dateRange.endDate) {
      this._getRequestSent();
    }
  };

  _onFocusChange = input => {
    const { filters } = this.state;
    filters.dateRange.focusedInput = input;
    this.setState({ filters });
  };

  _showRequests = () => {
    const { requests } = this.state;
    return requests && requests.map((request) => {
      return (
        <>
          <TableRow key={request._id}>
            <TableCell>{request._to.name.full}</TableCell>
            <TableCell>{request._requestedBook.title}</TableCell>
            <TableCell>{request.isSwap ? "Swap" : "Buy"}</TableCell>
            <TableCell
              onClick={() => request.isSwap ? this._onToggleViewDetailModal(request._swapBook?._id)
                : console.log()
              }
            ><Link className="bookOffered">{request.isSwap ? request._swapBook?.title : "-"}
              </Link>
            </TableCell>
            <TableCell>{moment(request.createdAt).format("DD MMM YYYY")}</TableCell>
            <TableCell>{request.status}</TableCell>
            <TableCell><i class='fas fa-comment text-theme' style={{ padding: '0 3px' }}
              onClick={() => this._startChat(request._id)}
            ></i></TableCell>
          </TableRow>
        </>
      )
    })
  }

  _startChat = async (request) => {
    try {
      const response = await getRequest(request);
      const { chatBox } = this.state;
      chatBox.isOpen = true;
      chatBox.request = response.request;
      this.setState({ chatBox });
    } catch (err) {
      console.log("Error here :>> ", err);
    }
  }

  _closeChatBox = () => {
    this.setState({
      chatBox: {
        isOpen: false,
        request: null
      }
    });
  }

  _paginatePage = (pageNumber) => {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this._getRequestSent();
    });
  };

  _getFirstElement = () => {
    return this.state.requests.length > 0 ? (this.state.currentPage * 10 - 9) : 0;
  }

  _getLastElement = () => {
    return this.state.requests.length === 10 ? this.state.currentPage * 10 : this.state.totalCount;
  }

  render() {
    const { requests, categories, filters, search, requestsType, chatBox, totalCount, userBookShelf } = this.state
    return (
      <>
        {!userBookShelf?.isOpen && (
          <>
            {!chatBox?.isOpen && (
              <div className="content">
                <div className="pgTitleWrap justify-content-between">
                  <h4 className="pageTitle">My Requests</h4>
                </div>

                <Box component="div" className="my-book-shelf             my-requests-button">
                  <Button className={requestsType.sent ? "activeBtn  fw-700" : "fw-700"}
                    onClick={() => this._toggleRequestsTypes("Sent")}
                  >Sent</Button>
                  <Button className={requestsType.received ? "activeBtn ml fw-700" : "fw-700 ml"}
                    onClick={() => this._toggleRequestsTypes("Recieved")}
                  >Received</Button>
                </Box>

                {requestsType.sent &&
                  <>
                    <Box component="div"
                      className="ModalFormRow my-requests-form customFilterWrap"
                    >
                      <Box component="div" className="searchBook">
                        <form autoComplete="off">
                          <label>
                            Book Name
                          </label>
                          <Box component="div" className="search-input">
                            <TextField
                              type="text"
                              autoComplete="current-text"
                              variant="outlined"
                              value={search}
                              onChange={(e) => this._handleOnChange("search", e.target.value)}
                            />
                          </Box>
                        </form>
                      </Box>

                      <Box component="div" className="searchBook customWidth">
                        <form autoComplete="off">
                          <Box component="div" className="filter-input">
                            <FormControl variant="outlined" className="m-0">
                              <label>Interest</label>
                              <Select
                                labelId="demo-simple-select-autowidth-label2"
                                value={filters.interest}
                                onChange={(e) => this._handleOnChange("interest", e.target.value)}
                              >
                                <MenuItem value="All">
                                  <em>All</em>
                                </MenuItem>
                                <MenuItem value="Swap">Swap</MenuItem>
                                <MenuItem value="Sell">Sell</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </form>
                      </Box>

                      <Box component="div" className="searchBook customWidth">
                        <form autoComplete="off">
                          <Box component="div" className="filter-input">
                            <FormControl variant="outlined" className="m-0">
                              <label>Category</label>
                              <Select
                                labelId="demo-simple-select-autowidth-label1"
                                value={filters.category}
                                onChange={(e) => this._handleOnChange("category", e.target.value)}
                              >
                                <MenuItem value="All">
                                  <em>All</em>
                                </MenuItem>
                                {categories?.length && categories.map((each, index) => {
                                  return (
                                    <MenuItem key={index} value={each}>{each}</MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                        </form>
                      </Box>

                      <Box component="div" className="searchBook">
                        <form autoComplete="off">
                          <FormControl variant="outlined" className="m-0">
                            <label>Date Range</label>
                            <CustomDateRangePicker
                              dateRange={this.state.filters.dateRange}
                              onDatesChange={this._onDatesChange}
                              onFocusChange={this._onFocusChange}
                            />
                          </FormControl>
                        </form>
                      </Box>
                    </Box>

                    {/* ============ Clear All Button =============*/}
                    <Box component="div" className="clear-all clear-all-custom" onClick={() => this._clearSuggestions()}>
                      Clear All
                      <i className="fa fa-refresh"></i>
                    </Box>

                    {/* ================== Table ===============*/}
                    <Card className="bookShellCard bookShellModal bookTableCard">
                      <CardBody className="w-100 h-100">
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Owner Name</TableCell>
                                <TableCell>Book</TableCell>
                                <TableCell>Interested in</TableCell>
                                <TableCell>Book Offered</TableCell>
                                <TableCell>Requested On</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Chat</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {requests.length ? this._showRequests()
                                :
                                <TableRow>
                                  <TableCell align="center" colspan="6">
                                    No request to show
                                  </TableCell>
                                </TableRow>
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardBody>
                    </Card>

                    {/* ============== Load More =============*/}
                    <Box component="div" className="paginationWrap">
                      <span className="poweredBy">
                        Showing  <b>{this._getFirstElement()}</b> to <b>{this._getLastElement()}</b> out of <b>{totalCount}</b>
                      </span>
                      <div>
                        <Paginate
                          totalPages={this.state.totalPages}
                          currentPage={this.state.currentPage}
                          paginate={this._paginatePage}
                        ></Paginate>
                      </div>
                    </Box>
                  </>
                }

                {requestsType.received &&
                  <MyRequestsRecieved
                    startChat={(request) => this._startChat(request)}
                    toUserBookShelf={(id) => this._handleUserBookShelf(id)}
                  />
                }
              </div>
            )}

            {chatBox.isOpen && (
              <Chat
                isOpen={chatBox.isOpen}
                request={chatBox.request}
                toggle={() => this._closeChatBox()}
              ></Chat>
            )}
            <ViewDetailModal
              isOpen={this.state.viewDetailModal.isOpen}
              creatorData={this.state.viewDetailModal.data}
              toggle={() => this._onToggleViewDetailModal()}
              toUserBookShelf={(id) => this._handleUserBookShelf(id)}
              reload={() => this._getRequestSent()}
            ></ViewDetailModal>
          </>
        )}
        {userBookShelf.isOpen &&
          <UserBookShelf
            isOpen={userBookShelf.isOpen}
            data={userBookShelf.userId}
            toggle={() => this._closeUserBookShelf()}
          ></UserBookShelf>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRequests);