import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makeDeleteRequests,
  uploadFileMultiPart
} from "./http-service";
import config from "../config/index";

export const login = (loginData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/login", false, loginData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const register = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + `/signup`, false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const forgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/forgotpassword", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const checkUsername = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/checkusername", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getUserBooks = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "/books", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addBook = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/book", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateBook = (data, id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + `/book/${id}`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const searchBookByIsbn = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/searchByIsbn", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const uploadCloudinary = (formData) => {
  return new Promise((resolve, reject) => {
    uploadFileMultiPart(
        false,
        formData,
        'image'
      ).then(res => {
        resolve(res);
      })
      .catch(e => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
}

export const getRequestsByUser = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/requestsByUser", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getRequestsToUser = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/requestsToUser", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateRequest = (data, id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + `/request/${id}`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getRequest = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `/request/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getTransactions = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/transactions", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getProfileDetails = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `/user`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateProfileDetails = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + `/user`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllSearchBooks = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/search/books", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBookDetail = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `/book/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBooksForSwap = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `/swapBooks/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createRequest = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + `/request`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getOtherUserBooks = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `/books/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getOtherUser = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `/user/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllChatThreads = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `/chat/threads`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getChatThreadById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `/chat/thread/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getChatThread = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `/chat/request/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createThread = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + `/chat/createThread`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getIpData = () => {
  return new Promise((resolve, reject) => {
    fetch("https://ipapi.co/json/", {
      method: "GET"
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((e) => {
        console.log("getIpData call error: ", e);
        reject(e);
      });
  });
};

export const addDevice = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/user/add/device", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const removeDevice = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "/user/remove/device", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getNotifications = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "/notifications", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

