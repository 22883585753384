import React, { Component, Fragment } from "react";

// material-ui components
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";
import LoginFooter from "../component/login-footer";
import { Link } from "react-router-dom";
import { forgotPassword } from "../../../http/http-calls";
import { ToastsStore } from "react-toasts";

class ForgotPage extends Component {
  state = {
    user: {
      handle: "",
    },
    isDirty: {
      handle: false,
    },
    errors: {}
  };

  _handleOnChange = (field, value) => {
    const { user, isDirty } = this.state;
    user[field] = value;
    isDirty[field] = true;
    this.setState({ user, isDirty }, () => {
      this._validateForm();
    });
  };
  _validateForm() {
    const { user, errors, isDirty } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === "handle" && isDirty.handle) {
        if (!user.handle.trim().length) {
          errors.handle = "*Required";
        } else if (
          user.handle.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(user.handle)
        ) {
          errors.handle = "Invalid email";
        } else {
          delete errors[each];
          isDirty.handle = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }
  _handleOnSubmit = (e) => {
    e.preventDefault();
    let isDirty = {
      handle: true,
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateForm();
      // console.log(errors);
      if (!errors) {
        // console.log("Make API call");
        this._forgotPassword()
      }
    });
  };
  _forgotPassword = () => {
    const { user } = this.state;
    forgotPassword(user).then((resp) => {
      // console.log("Forgot password resp here :- ", resp);
      this.setState({
        user: {
          handle: "",
        },
        isDirty: {
          handle: false,
        },
        errors: {}
      });
      ToastsStore.success("Please... Check Your Email ");
      this._backToLogin();
    }).catch((err) => {
      // console.log(err);
      ToastsStore.error(err.reason);
    });
  }
  render() {
    const { user, errors } = this.state
    return (
      <>
        <div className="loginPage">
          <Card>
            <CardContent className="h-100">
              <Grid
                container
                direction="row"
                justify="center"
                className="h-100"
              >
                <GridItem md={4} className="pl-0 h-100 d-md-none">
                  <img
                    src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=334&q=80"
                    className="fit-img"
                    alt=""
                  />
                </GridItem>
                <GridItem
                  md={8}
                  className="d-flex flex-column justify-content-between pr-0 h-100 w-100"
                >
                  <div className="d-flex align-items-center justify-content-center flex-column h-90">
                    <img
                      className="logoImg"
                      src={require("../../../assets/img/logo.png").default}
                      alt=""
                    />
                    <div className="w-100">
                      <Typography
                        component="h5"
                        variant="h5"
                        className="m-0 text-center"
                      >
                        Forgot Password
                      </Typography>
                      <form noValidate className="loginForm" onSubmit={this._handleOnSubmit}>
                        <FormGroup>
                          <FormControl>
                            <Input
                              placeholder="Email"
                              id="adornment-email"
                              name='handle'
                              value={user.handle}
                              onChange={(e) =>
                                this._handleOnChange("handle", e.target.value)
                              }
                              startAdornment={
                                <InputAdornment position="start">
                                  <i className="fa fa-envelope"></i>
                                </InputAdornment>
                              }
                            />
                            {errors && (
                            <small className="validation-error">
                              {errors.handle}
                            </small>
                          )}
                          </FormControl>

                          <FormControl className="loginButton">
                            <Fragment className="text-theme ml-1">
                              <Button className="formSubmit bg-theme fs-15" type='submit'>
                                Forgot Password
                              </Button>
                            </Fragment>
                          </FormControl>
                        </FormGroup>
                      </form>

                      <Typography
                        component="h6"
                        className="m-0 text-center text-secondary fs-15 fw-400"
                      >
                        <Link to="/login" className="text-theme">
                          Back to Login
                        </Link>
                      </Typography>
                    </div>
                  </div>

                  <LoginFooter></LoginFooter>
                </GridItem>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </>
    );
  }
}

export default ForgotPage;
