import React, { Component } from "react";
import CustomDateRangePicker from "../../general/components/dateRangePicker";
import moment from "moment";
// material-ui components
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import Box from '@material-ui/core/Box';
import Card from "../../general/components/theme-components/Card/Card";
import CardBody from "../../general/components/theme-components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import UserBookShelf from "./UserBookShelf";
import ViewDetailModal from "./ViewDetailModal";
import { getAllSearchBooks } from "../../../http/http-calls";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { ToastsStore } from "react-toasts";
import Paginate from "../../general/components/pagination";
import { CodeOutlined } from "@material-ui/icons";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      viewDetailModal: {
        isOpen: false,
        data: null,
      },
      search: {
        title: ""
      },
      filters: {
        type: "All",
        dateRange: {
          startDate: null,
          endDate: null,
          focusedInput: null,
          startDateId: "startDate",
          endDateId: "endDate"
        },
        condition: "All",
        distance: 10,
        category: "All"
      },
      books: [],
      categories: [],
      featuredCategories: [],
      action: {
        isOpen: false,
        type: "",
        selectedBook: ""
      },
      swapAction: {
        isOpen: false,
        swapBooks: [],
        selectedBook: ""
      },
      userId: "",
      userBookShelf: {
        isOpen: false,
        userId: null
      },
      totalPages: "",
      currentPage: 1,
      totalCount: "",
      isVisible: false,
    }; 
  }
  
  componentDidMount() {
    this._getAllBooks();
  }

  // componentDidUpdate() {
  //   // window.scrollTo(0, 0);
  //   setTimeout(() => {
  //     console.log("On home page :>>")
  //     // window.scrollTo(0, 0)
  //     // window.alert("Hiii")
  //     let objDiv = document.getElementById("books-panel");
  //     objDiv.scrollTop = 0;
  //   }, 3000);
  // }

  _getAllBooks = () => {
    this.props.showLoader();
    let { search, filters, featuredCategories, books, categories, currentPage, totalPages, totalCount } = this.state;
    const payload = {
      search: {},
      filters: {},
      pageNumber: currentPage
    }
    if (search.title !== undefined && search.title !== "") {
      payload.search.title = search.title;
    }
    if (filters.type !== undefined && filters.type !== "" && filters.type !== "All") {
      payload.filters.type = filters.type
    }
    if (filters.dateRange.startDate && filters.dateRange.endDate) {
      payload.filters.dateRange = {
        from: moment(filters.dateRange.startDate).format('DD/MM/YYYY'),
        to: moment(filters.dateRange.endDate).format('DD/MM/YYYY')
      }
    }
    if (filters.condition !== undefined && filters.condition !== "" && filters.condition !== "All") {
      payload.filters.condition = filters.condition
    }
    if (filters.category !== undefined && filters.category !== "" && filters.category !== "All") {
      payload.filters.category = filters.category
    }
    if (filters.distance !== undefined && filters.distance !== null) {
      payload.filters.distance = filters.distance
    }
    // console.log(payload);
    getAllSearchBooks(payload).then((resp) => {
      // console.log("List of books here :- ", resp);
      books = resp.books;
      categories = resp.setting.categories;
      featuredCategories = resp.setting.categories.filter((each, index) => index < 4);
      currentPage = resp.currentPage;
      totalPages = resp.totalPage;
      totalCount = resp.totalCount
      this.setState({ books, categories, featuredCategories, currentPage, totalPages, totalCount }, () => {
        // window.scrollTo(0, 0);
        // console.log(this.myRef)
        // this.myRef.current.scrollTo(0, 0);
      });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Failed to load books :- " + err.reason);
    });
  }

  _handleOnChange = (field, value) => {
    const { search, filters } = this.state;
    if (field === "title") {
      search[field] = value;
    } else {
      filters[field] = value;
    }
    this.setState({ search, filters }, () => {
      this._getAllBooks();
    });
  }

  _clearSuggestion = () => {
    this.setState({
      search: {
        title: "",
        location: ""
      },
      filters: {
        type: "All",
        dateRange: {
          startDate: null,
          endDate: null,
          focusedInput: null,
          startDateId: "startDate",
          endDateId: "endDate"
        },
        condition: "All",
        distance: 10,
        category: "All"
      },
      isVisible: false
    }, () => {
      this._getAllBooks();
    });
  }

  _onDatesChange = (startDate, endDate) => {
    const { filters } = this.state;
    filters.dateRange.startDate = startDate;
    filters.dateRange.endDate = endDate;
    this.setState({ filters });
    if (filters.dateRange.startDate && filters.dateRange.endDate) {
      this._getAllBooks();
    }
  };

  _onFocusChange = input => {
    const { filters } = this.state;
    filters.dateRange.focusedInput = input;
    this.setState({ filters });
  };

  _showBooks = () => {
    const { books } = this.state;
    return books && books.map((book) => {
      return (
        <GridItem xs={12} sm={6} className="my-book-shelf-grid-item" ref={this.myRef}>
          <Card className="bookShellCard viewDetailHover" onClick={() => this._onToggleViewDetailModal(book._id)}>
            <Grid direction="row" justify="center" >
              <GridItem
                xs={12}
                lg={5}
                spacing={1}
                className="card-price-container  bookShellCard-grid-item"
                style={{ padding: "0px !important" }}
              >
                <img
                  src={book.imageUrl[0]}
                  alt=""
                  className="card-img"
                />
                <p className="card-price">{book.bookStatusMode === "Swap" ? book.bookStatusMode
                  : book.bookStatusMode === "Proud Owner" ? book.bookStatusMode
                    : `Sell: ₹${book.price}`}
                </p>
              </GridItem>
              <GridItem
                xs={12}
                lg={7}
                spacing={1}
                className="bookShellCard-grid-item"
              >
                <CardBody className="w-100 h-100">
                  <h4 className="card-heading">
                    {book.title}
                  </h4>
                  <h6 className="card-author">Author: {book?.author?.length && book.author.toString()} </h6>

                  <hr />
                  <p className="card-para">
                    {book.description}
                  </p>

                  {/* =========== Category & Location ============= */}
                  {/* <Grid direction="row" justify="space-between" className="category-location">
                    <GridItem
                      xs={5}
                      spacing={1}
                      className="bookShellCard-grid-item"
                    >
                      <span className="bookShellCard-category fs-12">Category: {book.category.toString()}</span>
                    </GridItem>

                    <GridItem
                      xs={5}
                      spacing={1}
                      className="bookShellCard-grid-item"
                    >
                      <span className="bookShellCard-location fs-12">Condition: {book.condition}</span>
                    </GridItem>
                  </Grid> */}

                  <div className="category-location">
                    <span className="bookShellCard-category fs-12">Category: <span>{book.category.toString()}</span></span>
                    <span className="bookShellCard-location fs-12">Condition: <span>{book.condition}</span></span>
                  </div>

                  {book.bookStatusMode !== "Proud Owner" &&
                    <div className="card-button-container">
                      {book?.bookStatusMode === "Both" ?
                        <>
                          <Button className="swap" round>
                            Swap
                          </Button>
                          <Button className="sale" round>
                            Buy
                          </Button>
                        </>
                        : book.bookStatusMode === "Swap" ?
                          <Button className="swap" round>
                            Swap
                          </Button>
                          : <Button className="sale" round>
                            Buy
                          </Button>
                      }
                    </div>
                  }
                </CardBody>
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
      )
    })
  }

  _onToggleViewDetailModal = (data) => {
    let { viewDetailModal } = JSON.parse(JSON.stringify(this.state));
    viewDetailModal.isOpen = !viewDetailModal.isOpen;
    if (data !== undefined) {
      viewDetailModal.data = data;
    } else {
      viewDetailModal.data = null;
    }
    this.setState({
      viewDetailModal,
    });
  }

  _handleUserBookShelf = (id) => {
    this.setState({
      userBookShelf: {
        isOpen: true,
        userId: id
      }
    });
  }

  _closeUserBookShelf = () => {
    this.setState({
      userBookShelf: {
        isOpen: false,
        userId: null
      }
    });
  }

  _handleDistanceIgnore = (value) => {
    const { filters } = this.state;
    if (value) {
      filters.distance = ""
    } else {
      filters.distance = 10
    }
    this.setState({ filters }, () => {
      this._getAllBooks();
    });
  }

  _setVisibility = () => {
    let { isVisible } = this.state;
    isVisible = true;
    this.setState({ isVisible });
  }

  _switchVisibility = () => {
    let { isVisible, search } = this.state;
    if (search.title?.trim().length) {
      isVisible = true;
    } else {
      isVisible = false;
    }
    this.setState({ isVisible });
  }

  _paginatePage = (pageNumber) => {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this._getAllBooks();
      // window.scrollTo(0, 0);
    });
  };

  _getFirstElement = () => {
    return this.state.books.length > 0 ? (this.state.currentPage * 10 - 9) : 0;
  }

  _getLastElement = () => {
    return this.state.books.length === 10 ? this.state.currentPage * 10 : this.state.totalCount;
  }

  render() {
    const { books, categories, search, filters, userBookShelf, totalCount, isVisible } = this.state;

    return (
      <>
        <div className="content browse-all-books">
          {!userBookShelf.isOpen
            &&
            <div>
              <div className="pgTitleWrap justify-content-between">
                <h4 className="pageTitle">Browse All Books</h4>
              </div>
              {/* ======= Browse All Books ======== */}
              <Box component="div" className="searchBook">
                <form autoComplete="off">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className="ModalFormRow my-requests-form homePgFilter"
                  >
                    <GridItem xs={12} sm={4}>
                      <Box component="div" className="search-input">
                        <label>
                          Book Name
                        </label>
                        <TextField
                          type="text"
                          autoComplete="current-text"
                          variant="outlined"
                          value={search.title}
                          onChange={(e) => this._handleOnChange("title", e.target.value)}
                          onFocus={() => this._setVisibility()}
                          onBlur={() => this._switchVisibility()}
                        />
                      </Box>
                      {isVisible && (
                        <Box>
                          <FormGroup>
                            <FormControlLabel
                              className="ignoreDistance"
                              value="end"
                              control={<Checkbox color="primary" />}
                              label="Ignore Distance"
                              labelPlacement="end"
                              onClick={(e) => this._handleDistanceIgnore(e.target.checked)}
                            />
                          </FormGroup>
                        </Box>
                      )}
                    </GridItem>

                    <GridItem xs={6} sm={4} style={{ alignSelf: 'flex-start' }}>
                      <FormControl variant="outlined" className="m-0">
                        <label>All Category</label>
                        <Select
                          labelId="demo-simple-select-autowidth-label2"
                          value={filters.category}
                          onChange={(e) => this._handleOnChange("category", e.target.value)}
                        >
                          <MenuItem value="All">
                            <em>All</em>
                          </MenuItem>
                          {categories?.length && categories.map((category, index) => {
                            return (
                              <MenuItem key={index} value={category}>{category}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={6} sm={4} style={{ alignSelf: 'flex-start' }}>
                      <FormControl variant="outlined" className="m-0">
                        <label>Condition</label>
                        <Select
                          labelId="demo-simple-select-autowidth-label2"
                          value={filters.condition}
                          onChange={(e) => this._handleOnChange("condition", e.target.value)}
                        >
                          <MenuItem value="All">
                            <em>All</em>
                          </MenuItem>
                          <MenuItem value="New">New</MenuItem>
                          <MenuItem value="Good">Good</MenuItem>
                          <MenuItem value="Fair">Fair</MenuItem>
                          <MenuItem value="Poor">Poor</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={6} sm={4}>
                      <FormControl variant="outlined" className="m-0">
                        <label>All Type</label>
                        <Select
                          labelId="demo-simple-select-autowidth-label2"
                          value={filters.type}
                          onChange={(e) => this._handleOnChange("type", e.target.value)}
                        >
                          <MenuItem value="All">
                            <em>All</em>
                          </MenuItem>
                          <MenuItem value="Swap">Swap</MenuItem>
                          <MenuItem value="Sell">Sell</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={6} sm={4}>
                      <FormControl variant="outlined" className="m-0">
                        <label>Distance (Km)</label>
                        <Select
                          labelId="demo-simple-select-autowidth-label2"
                          value={filters.distance}
                          onChange={(e) => this._handleOnChange("distance", e.target.value)}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={4}>
                      <FormControl variant="outlined" className="m-0">
                        <label>Date Range</label>
                        <CustomDateRangePicker
                          dateRange={this.state.filters.dateRange}
                          onDatesChange={this._onDatesChange}
                          onFocusChange={this._onFocusChange}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>

                  {/* ============ Clear All Button =============*/}
                  {/* <Box component="div" className="clear-all">
                    <span className="fs-18 fw-700 text-theme"
                      onClick={() => this._clearSuggestion()}
                    >Clear All</span>
                    <ClearAllIcon />
                  </Box> */}

                  <Box component="div" className="clear-all clear-all-custom mt-0" onClick={() => this._clearSuggestion()}>
                    Clear All
                    <i className="fa fa-refresh"></i>
                  </Box>
                </form>

              </Box>

              {/* ======== Featured Categories ========= */}
              {/* <Box component="div" className="featured-categories">
                <span className="fs-20 text-theme fw-700">Featured Categories</span>
                <Grid
                  container
                  direction="row"
                  className="featured-categories-container"
                >
                  {featuredCategories?.length && featuredCategories.map((each, index) => {
                    return (
                      <GridItem xs={12} sm={3} key={index}>
                        <Box component="div" className="featured-categories-box">
                          <img
                            className="fit-img featured-categories-img"
                            src="https://img.buzzfeed.com/buzzfeed-static/static/2020-12/29/18/campaign_images/541ad6e982f0/here-are-the-most-beautiful-book-covers-of-2020-2-1522-1609265736-10_dblbig.jpg?resize=1200:*"
                            alt="Book Cover"
                          />
                          <Link className="featured-categories-link fs-20 fw-700 text-theme"
                            onClick={() => this._handleOnChange("category", each)}
                          >{each}</Link>
                        </Box>
                      </GridItem>
                    )
                  })}
                </Grid>
              </Box> */}

              {/* ======== Featured Books ========= */}
              <Box component="div" className="featured-categories">
                <span className="fs-20 text-theme fw-700">Featured Books</span>
                <Grid
                  container
                  direction="row"
                  className="my-book-shelf mt-15"
                  id="books-panel"
                >
                  {books?.length ? this._showBooks()
                    : <div style={{ textAlign: "center", width: "inherit" }}><h5>No books to show</h5></div>
                  }
                </Grid >
              </Box >
              {/* ============== View All =============*/}
              <Box component="div" className="paginationWrap">
                <span className="poweredBy">
                  Showing  <b>{this._getFirstElement()}</b> to <b>{this._getLastElement()}</b> out of <b>{totalCount}</b>
                </span>
                <div>
                  <Paginate
                    totalPages={this.state.totalPages}
                    currentPage={this.state.currentPage}
                    paginate={this._paginatePage}
                  ></Paginate>
                </div>
              </Box>
            </div>
          }

          {userBookShelf.isOpen
            &&
            <UserBookShelf
              isOpen={userBookShelf.isOpen}
              data={userBookShelf.userId}
              toggle={() => this._closeUserBookShelf()}
            ></UserBookShelf>
          }

          <ViewDetailModal
            isOpen={this.state.viewDetailModal.isOpen}
            creatorData={this.state.viewDetailModal.data}
            toggle={() => this._onToggleViewDetailModal()}
            reload={() => this._getAllBooks()}
            toUserBookShelf={(id) => this._handleUserBookShelf(id)}
          ></ViewDetailModal>
        </div >
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);