import React, { Component } from "react";

// material-ui components
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import Card from "../../general/components/theme-components/Card/Card";
import CardBody from "../../general/components/theme-components/Card/CardBody";
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import MyBookShelfModal from "./MyBookShelfModal";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import Box from '@material-ui/core/Box';
import { ToastsStore } from "react-toasts";
import Switch from '@material-ui/core/Switch';
import { getUserBooks, updateBook } from "../../../http/http-calls";

class MyBookShelf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myBookShelfModal: {
        isOpen: false,
        data: null,
        isEdit: false
      },
      books: [],
      setting: {}
    };

    this._onToggleMyBookShelfModal = this._onToggleMyBookShelfModal.bind(this);
  }

  componentDidMount() {
    this._getBookList();
  }

  _getBookList = () => {
    this.props.showLoader();
    getUserBooks().then((resp) => {
      // console.log("List of Books here :- ", resp);
      this.setState({
        books: resp.books,
        setting: resp.setting
      });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Failed to load books :- " + err.reason);
    });
  }

  _showBooks = () => {
    const { books } = this.state;
    return books && books.map((book) => {
      return (
        <>
          <GridItem xs={12} sm={6} className="my-book-shelf-grid-item" key={book._id}>
            <Card className="bookShellCard">
              <Grid direction="row" justify="center" > {/** 1 */}
                <GridItem
                  xs={12}
                  lg={5}
                  spacing={1}
                  className="card-price-container bookShellCard-grid-item"
                  style={{ padding: "0px !important" }}
                >
                  <img
                    src={book.imageUrl[0]}
                    alt=""
                    className="card-img"
                  />
                  <p className="card-price">{book.bookStatusMode === "Swap" ? book.bookStatusMode
                    : book.bookStatusMode === "Proud Owner" ? book.bookStatusMode : `Sell: ₹${book.price}`}</p>
                </GridItem>
                <GridItem
                  xs={12}
                  lg={7}
                  spacing={1}
                  className="bookShellCard-grid-item"
                >
                  <CardBody className="w-100 h-100">
                    <Box component="div" className="books-top-icons">
                      {/* <i class='far fa-comment fs-16'></i> */}
                      <i class="fa fa-pencil fs-14"
                        onClick={() => this._editBook(book._id)}
                      ></i>
                      <Switch
                        checked={book?.isActive}
                        color="default"
                        onChange={(e) => this._handleActiveToggle(e.target.checked, book._id)}
                      />
                    </Box>
                    <h4 className="card-heading">
                      {book.title}
                    </h4>
                    <h6 className="card-author">By: {book.author.toString()} </h6>

                    <hr />
                    <p className="card-para">
                      {book.description}
                    </p>

                    {book.bookStatusMode !== "Proud Owner" &&
                      <div className="card-button-container">
                        {book.bookStatusMode === "Both"
                          ?
                          <>
                            <Button className="swap" round>
                              Swap
                            </Button>
                            <Button className="sale" round>
                              Sell
                            </Button>
                          </>
                          :
                          <Button
                            className={book.bookStatusMode === "Proud Owner" ? "proud-owner"
                              : book.bookStatusMode === "Swap" ? "swap" : "sale"
                            }
                            round
                          >
                            {book.bookStatusMode}
                          </Button>
                        }
                      </div>
                    }
                  </CardBody>
                </GridItem>
              </Grid>
            </Card>
          </GridItem>
        </>
      )
    });
  }

  _handleActiveToggle = (temp, id) => {
    this.props.showLoader();
    updateBook({ isActive: temp }, id).then((resp) => {
      // console.log("Book update response here :- ", resp);
      this._getBookList();
      ToastsStore.success("Book Updated successfully");
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Failed to update book :- " + err.reason);
    });
  }

  _onToggleMyBookShelfModal() {
    let { myBookShelfModal } = JSON.parse(JSON.stringify(this.state));
    myBookShelfModal.isOpen = !myBookShelfModal.isOpen;
    myBookShelfModal.data = null;
    myBookShelfModal.isEdit = false;
    this.setState({
      myBookShelfModal,
    });
  }

  _editBook = (id) => {
    const { myBookShelfModal } = this.state;
    myBookShelfModal.isOpen = true;
    myBookShelfModal.data = id;
    myBookShelfModal.isEdit = true;
    this.setState({ myBookShelfModal });
  }

  render() {
    return (
      <>
        <div className="content">
          <div className="pgTitleWrap justify-content-between align-items-center">
            <h4 className="pageTitle">My Book Shelf</h4>
            <Button
              className="bg-theme addBtn"
              round
              onClick={() => this._onToggleMyBookShelfModal()}
            >
              Add New
            </Button>
          </div>
          <Grid
            container
            direction="row"
            className="my-book-shelf"
          >
            {this.state.books.length ? this._showBooks()
              : <div style={{ textAlign: "center", width: "inherit" }}><h5>No Books added</h5></div>
            }

            <MyBookShelfModal
              isOpen={this.state.myBookShelfModal.isOpen}
              creatorData={this.state.myBookShelfModal}
              toggle={() => this._onToggleMyBookShelfModal()}
              reload={() => this._getBookList()}
              settingData={this.state.setting}
            ></MyBookShelfModal>

          </Grid>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBookShelf);
