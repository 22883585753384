import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";

// material-ui components
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import Card from "../../general/components/theme-components/Card/Card";
import CardHeader from "../../general/components/theme-components/Card/CardHeader";
import CardBody from "../../general/components/theme-components/Card/CardBody";
import CardFooter from "../../general/components/theme-components/Card/CardFooter";
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import TextField from "@material-ui/core/TextField";
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { ToastsStore } from "react-toasts";
import { searchBookByIsbn, uploadCloudinary, addBook, getBookDetail, updateBook } from "../../../http/http-calls";

class MyBookShelfModal extends Component {
  state = {
    bookData: {
      title: "",
      author: "",
      isbnNo: "",
      description: "",
      language: "",
      category: "",
      topic: "",
      condition: "",
      price: "",
      bookMode: "",
      imageUrl: "",
      imageUrl2: "",
      imageUrl3: ""
    },
    isDirty: {
      title: false,
      author: false,
      language: false,
      category: false,
      topic: false,
      condition: false,
      price: false,
      bookMode: false
    },
    errors: {},
    settings: {},
    authors: [],
    invisible: true,
    bookId: "",
    isEditable: false,
    bookBackUp: {}
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.creatorData.data !== null
        && this.props.settingData
      ) {
        this.setState({
          bookId: this.props.creatorData.data,
          isEditable: this.props.creatorData.isEdit,
          settings: this.props.settingData
        }, () => {
          this._getBookDetails();
        });
      } else if (this.props.settingData) {
        this.setState({
          settings: this.props.settingData,
          bookId: "",
          isEditable: false
        });
      }
    }
  }

  _getBookDetails = () => {
    let { bookId, invisible } = this.state;
    this.props.showLoader();
    getBookDetail(bookId).then((resp) => {
      // console.log("Book here :>> ", resp);
      if (resp.book?.bookStatusMode
        && (resp.book.bookStatusMode === "Sell" || resp.book.bookStatusMode === "Both")) {
        invisible = false;
      }
      this.setState({
        bookData: {
          title: resp.book?.title ? resp.book.title : "",
          isbnNo: resp.book?.isbnNo ? resp.book.isbnNo : "",
          description: resp.book?.description ? resp.book.description : "",
          language: resp.book?.language ? resp.book.language : "",
          category: resp.book?.category.length ? resp.book.category[0] : "",
          topic: resp.book?.topic ? resp.book.topic : "",
          condition: resp.book?.condition ? resp.book.condition : "",
          price: resp.book?.price ? resp.book.price : "",
          bookMode: resp.book?.bookStatusMode ? resp.book.bookStatusMode : "",
          imageUrl: resp.book?.imageUrl?.length ? resp.book.imageUrl[0] : "",
          imageUrl2: resp.book?.imageUrl[1] ? resp.book.imageUrl[1] : "",
          imageUrl3: resp.book?.imageUrl[2] ? resp.book.imageUrl[2] : "",
        },
        authors: resp.book?.author?.length ? resp.book.author : [],
        bookBackUp: resp.book,
        invisible
      });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Book Details loading Failed :- " + err.reason);
      this._closeModal();
    });
  }

  handleDelete = (value) => {
    let { authors, isDirty } = this.state;
    authors = authors.filter((each) => each !== value);
    isDirty.author = true;
    this.setState({
      authors,
      isDirty
    }, () => this._validateFields());
  };

  _handleOnChange = (field, value) => {
    let { bookData, isDirty, invisible, errors } = this.state;
    bookData[field] = value;
    isDirty[field] = true;
    if (field === "bookMode") {
      if (value === "Sell" || value === "Both") {
        invisible = false
      } else {
        invisible = true
        delete errors.price
      }
    }
    this.setState({
      bookData, isDirty, invisible, errors
    }, () => {
      this._validateFields();
    });
  }

  _validateFields = () => {
    const { bookData, isDirty, errors, authors } = this.state;
    Object.keys(bookData).forEach((each) => {
      if (each === "title" && isDirty.title) {
        if (!bookData.title.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.title = false;
        }
      } else if (each === "author" && isDirty.author) {
        if (!authors.length && !bookData.author.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.author = false;
        }
      } else if (each === "language" && isDirty.language) {
        if (!bookData.language.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.language = false;
        }
      } else if (each === "category" && isDirty.category) {
        if (!bookData.category.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.category = false;
        }
      } else if (each === "topic" && isDirty.topic) {
        if (!bookData.topic.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.topic = false;
        }
      } else if (each === "condition" && isDirty.condition) {
        if (!bookData.condition.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.condition = false;
        }
      } else if (each === "price" && isDirty.price) {
        if (!bookData.price.toString().trim().length) {
          errors[each] = "*Required";
        } else if (bookData.price.toString().trim().length
          && !bookData.price.toString().match(/^[0-9]/)
        ) {
          errors[each] = "Invalid price";
        } else {
          delete errors[each];
          isDirty.price = false;
        }
      } else if (each === "bookMode" && isDirty.bookMode) {
        if (!bookData.bookMode.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.bookMode = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _addBook = (e) => {
    e.preventDefault();
    const { bookData, authors, isEditable, bookBackUp, bookId } = this.state;
    let payload = {};
    let isDirty = {};
    if (bookData.bookMode === "Sell" || bookData.bookMode === "Both") {
      isDirty = {
        title: true,
        author: true,
        language: true,
        category: true,
        topic: true,
        condition: true,
        bookMode: true,
        price: true
      };
      this.setState({ isDirty }, () => {
        const errors = this._validateFields();
        if (!errors) {
          if (!isEditable) {
            payload = {
              isbnNo: bookData.isbnNo,
              title: bookData.title,
              author: authors,
              description: bookData.description,
              language: bookData.language,
              category: [bookData.category],
              topic: bookData.topic,
              condition: bookData.condition,
              price: bookData.price,
              bookStatusMode: bookData.bookMode,
              imageUrl: [bookData.imageUrl, bookData.imageUrl2, bookData.imageUrl3]
            };
            this.props.showLoader();
            addBook(payload).then((resp) => {
              // console.log("Add book response here :- ", resp);
              this.props.hideLoader();
              ToastsStore.success("Book Added Successfully");
              this._closeModal();
            }).catch((err) => {
              this.props.hideLoader();
              ToastsStore.error("Adding Book Failed :- " + err.reason);
            });
          } else {
            Object.keys(bookData).forEach((each) => {
              if (each === "title") {
                if (bookBackUp.title === undefined || bookBackUp.title !== bookData.title) {
                  payload.title = bookData.title;
                }
              } else if (each === "description") {
                if (bookBackUp.description === undefined || bookBackUp.description !== bookData.description) {
                  payload.description = bookData.description;
                }
              } else if (each === "isbnNo") {
                if (bookBackUp.isbnNo === undefined || bookBackUp.isbnNo !== bookData.isbnNo) {
                  payload.isbnNo = bookData.isbnNo;
                }
              } else if (each === "language") {
                if (bookBackUp.language === undefined || bookBackUp.language !== bookData.language) {
                  payload.language = bookData.language;
                }
              } else if (each === "category") {
                if (!Array.isArray(bookBackUp.category) || JSON.stringify(bookBackUp.category) !== bookData.category) {
                  payload.category = [bookData.category];
                }
              } else if (each === "topic") {
                if (bookBackUp.topic === undefined || bookBackUp.topic !== bookData.topic) {
                  payload.topic = bookData.topic;
                }
              } else if (each === "condition") {
                if (bookBackUp.condition === undefined || bookBackUp.condition !== bookData.condition) {
                  payload.condition = bookData.condition;
                }
              } else if (each === "price") {
                if (bookBackUp.price === undefined || bookBackUp.price !== bookData.price) {
                  payload.price = bookData.price;
                }
              } else if (each === "bookMode") {
                if (bookBackUp.bookStatusMode === undefined || bookBackUp.bookStatusMode !== bookData.bookMode) {
                  payload.bookStatusMode = bookData.bookMode;
                }
              } else if ((each === "imageUrl") || (each === "imageUrl2") || (each === "imageUrl3")) {
                if (!Array.isArray(bookBackUp.imageUrl)
                  || bookBackUp.imageUrl[0] !== bookData.imageUrl
                  || bookBackUp.imageUrl[1] === undefined
                  || bookBackUp.imageUrl[1] !== bookData.imageUrl2
                  || bookBackUp.imageUrl[2] === undefined
                  || bookBackUp.imageUrl[2] !== bookData.imageUrl3
                ) {
                  payload.imageUrl = [bookData.imageUrl, bookData.imageUrl2, bookData.imageUrl3];
                }
              }
            });
            if (!Array.isArray(bookBackUp.author) || JSON.stringify(bookBackUp.author) !== JSON.stringify(authors)) {
              payload.author = authors;
            }
            this.props.showLoader();
            updateBook(payload, bookId).then((resp) => {
              // console.log("Updated book response here :>> ", resp);
              ToastsStore.success("Book Updated Successfully");
              this.props.hideLoader();
              this._closeModal();
            }).catch((err) => {
              this.props.hideLoader();
              ToastsStore.error("Book Updation Failed :- " + err.reason);
            });
          }
        }
      })
    } else {
      isDirty = {
        title: true,
        author: true,
        language: true,
        category: true,
        topic: true,
        condition: true,
        bookMode: true
      };
      this.setState({ isDirty }, () => {
        const errors = this._validateFields();
        if (!errors) {
          if (!isEditable) {
            payload = {
              isbnNo: bookData.isbnNo,
              title: bookData.title,
              author: authors,
              description: bookData.description,
              language: bookData.language,
              category: [bookData.category],
              topic: bookData.topic,
              condition: bookData.condition,
              bookStatusMode: bookData.bookMode,
              imageUrl: [bookData.imageUrl, bookData.imageUrl2, bookData.imageUrl3]
            };
            this.props.showLoader();
            addBook(payload).then((resp) => {
              // console.log("Add book response here :- ", resp);
              this.props.hideLoader();
              ToastsStore.success("Book Added Successfully");
              this._closeModal();
            }).catch((err) => {
              this.props.hideLoader();
              ToastsStore.error("Adding Book Failed :- " + err.reason);
            });
          } else {
            Object.keys(bookData).forEach((each) => {
              if (each === "title") {
                if (bookBackUp.title === undefined || bookBackUp.title !== bookData.title) {
                  payload.title = bookData.title;
                }
              } else if (each === "description") {
                if (bookBackUp.description === undefined || bookBackUp.description !== bookData.description) {
                  payload.description = bookData.description;
                }
              } else if (each === "isbnNo") {
                if (bookBackUp.isbnNo === undefined || bookBackUp.isbnNo !== bookData.isbnNo) {
                  payload.isbnNo = bookData.isbnNo;
                }
              } else if (each === "language") {
                if (bookBackUp.language === undefined || bookBackUp.language !== bookData.language) {
                  payload.language = bookData.language;
                }
              } else if (each === "category") {
                if (!Array.isArray(bookBackUp.category) || JSON.stringify(bookBackUp.category) !== bookData.category) {
                  payload.category = [bookData.category];
                }
              } else if (each === "topic") {
                if (bookBackUp.topic === undefined || bookBackUp.topic !== bookData.topic) {
                  payload.topic = bookData.topic;
                }
              } else if (each === "condition") {
                if (bookBackUp.condition === undefined || bookBackUp.condition !== bookData.condition) {
                  payload.condition = bookData.condition;
                }
              } else if (each === "bookMode") {
                if (bookBackUp.bookStatusMode === undefined || bookBackUp.bookStatusMode !== bookData.bookMode) {
                  payload.bookStatusMode = bookData.bookMode;
                }
              } else if ((each === "imageUrl") || (each === "imageUrl2") || (each === "imageUrl3")) {
                if (!Array.isArray(bookBackUp.imageUrl)
                  || bookBackUp.imageUrl[0] !== bookData.imageUrl
                  || bookBackUp.imageUrl[1] === undefined
                  || bookBackUp.imageUrl[1] !== bookData.imageUrl2
                  || bookBackUp.imageUrl[2] === undefined
                  || bookBackUp.imageUrl[2] !== bookData.imageUrl3
                ) {
                  payload.imageUrl = [bookData.imageUrl, bookData.imageUrl2, bookData.imageUrl3];
                }
              }
            });
            if (!Array.isArray(bookBackUp.author) || JSON.stringify(bookBackUp.author) !== JSON.stringify(authors)) {
              payload.author = authors;
            }
            this.props.showLoader();
            updateBook(payload, bookId).then((resp) => {
              // console.log("Updated book response here :>> ", resp);
              ToastsStore.success("Book Updated Successfully");
              this.props.hideLoader();
              this._closeModal();
            }).catch((err) => {
              this.props.hideLoader();
              ToastsStore.error("Book Updation Failed :- " + err.reason);
            });
          }
        }
      });
    }
  }

  _addAuthor = (e, keyPressed) => {
    if (e.keyCode === 13 || keyPressed === "Tab") {
      let { authors, bookData, isDirty } = this.state;
      authors.push(bookData.author);
      bookData.author = "";
      isDirty.author = true;
      this.setState({
        authors,
        bookData,
        isDirty
      }, () => this._validateFields());
    }
  }

  _searchBook = () => {
    let { bookData, authors, isDirty } = this.state;
    if (bookData.isbnNo.trim().length) {
      const payload = {
        isbnNo: bookData.isbnNo
      };
      this.props.showLoader();
      searchBookByIsbn(payload).then((resp) => {
        this.props.hideLoader();
        if (resp.book !== null) {
          bookData.title = resp.book.title;
          bookData.category = resp.book.category[0];
          bookData.description = resp.book.description;
          bookData.language = resp.book.language;
          bookData.topic = resp.book.topic;
          authors = resp.book.author;
          Object.keys(bookData).forEach((each) => {
            isDirty[each] = true
          });
          this.setState({
            authors,
            bookData,
            isDirty
          }, () => this._validateFields());
        } else {
          ToastsStore.error("No book found for the given isbn number");
        }
      }).catch((err) => {
        this.props.hideLoader();
        console.log(err);
      });
    }
  }

  _clearSuggestion = () => {
    this.setState({
      authors: [],
      bookData: {
        title: "",
        author: "",
        isbnNo: "",
        description: "",
        language: "",
        category: "",
        topic: "",
        condition: "",
        price: "",
        bookMode: ""
      },
      isDirty: {
        title: false,
        author: false,
        language: false,
        category: false,
        topic: false,
        condition: false,
        price: false,
        bookMode: false
      },
      errors: {},
      bookId: "",
      isEditable: false,
      bookBackUp: {}
    });
  }

  _handleFileChange = (e, count) => {
    const { bookData } = this.state;
    const imageData = e.target.files[0];
    const fd = new FormData();
    fd.append("file", imageData);
    this.props.showLoader();
    if (count === '0') {
      uploadCloudinary(fd).then((resp) => {
        bookData.imageUrl = resp.url;
        this.setState({ bookData });
        this.props.hideLoader();
      }).catch((err) => {
        this.props.hideLoader();
        ToastsStore.error("Failed to upload image :- " + err.reason);
      });
    } else if (count === '1') {
      uploadCloudinary(fd).then((resp) => {
        bookData.imageUrl2 = resp.url;
        this.setState({ bookData });
        this.props.hideLoader();
      }).catch((err) => {
        this.props.hideLoader();
        ToastsStore.error("Failed to upload image :- " + err.reason);
      });
    } else if (count === '2') {
      uploadCloudinary(fd).then((resp) => {
        bookData.imageUrl3 = resp.url;
        this.setState({ bookData });
        this.props.hideLoader();
      }).catch((err) => {
        this.props.hideLoader();
        ToastsStore.error("Failed to upload image :- " + err.reason);
      });
    }
  }

  _closeModal = () => {
    this.setState({
      bookData: {
        title: "",
        author: "",
        isbnNo: "",
        description: "",
        language: "",
        category: "",
        topic: "",
        condition: "",
        price: "",
        bookMode: "",
        imageUrl: "",
        imageUrl2: "",
        imageUrl3: ""
      },
      isDirty: {
        title: false,
        author: false,
        language: false,
        category: false,
        topic: false,
        condition: false,
        price: false,
        bookMode: false
      },
      errors: {},
      imageUrl: "",
      authors: [],
      invisible: true
    });
    this.props.toggle(null);
    this.props.reload();
  }

  render() {
    const { bookData, errors, settings, authors, invisible, isEditable } = this.state;

    return (
      <Modal
        open={this.props.isOpen}
        onClose={() => this._closeModal()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="MyBookShelfModal"
      >
        <div className="modalBody">
          <Card className="bookShellCard bookShellModal">
            <CardHeader className="card-header">
              <h3 className="card-heading">{isEditable ? "Edit Book" : "Add Book"}</h3>
            </CardHeader>
            <CardBody className="w-100 h-100">
              <form noValidate autoComplete="off">
                {/* =================================
                ============== Text Fields and Upload in Row ==========
                 ======================================*/}
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className="ModalFormRow"
                >
                  <GridItem xs={12} sm={6} className="order-2">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "end",
                      }}
                    >
                      <TextField label="Search by ISBN"
                        value={bookData.isbnNo}
                        onChange={(e) => this._handleOnChange("isbnNo", e.target.value)}
                      />
                      <div style={{ display: "flex", paddingTop: "28px" }}>
                        <SearchIcon
                          onClick={() => this._searchBook()}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <ClearAllIcon
                          onClick={() => this._clearSuggestion()}
                        />
                      </div>
                    </div>
                    <TextField
                      label="Book Title"
                      value={bookData.title}
                      onChange={(e) => this._handleOnChange("title", e.target.value)}
                    />
                    {errors && (
                      <small
                        className='validation-error'
                      >
                        {errors.title}
                      </small>
                    )}

                    <label style={{ fontSize: 12, color: '#828282', fontWeight: 400, margin: '10px 0 4px', display: 'block' }}>Additional Info</label>
                    <TextareaAutosize
                      aria-label="minimum height"
                      rowsMin={4}
                      placeholder="Additional Info"
                      style={{ width: "100%" }}
                      value={bookData.description}
                      onChange={(e) => this._handleOnChange("description", e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputLabel className="uploadProfile">
                      <Input
                        type="file"
                        style={{ display: "none" }}
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={(e) => this._handleFileChange(e, '0')}
                      />
                      {bookData && bookData.imageUrl?.trim().length
                        ? <img
                          src={bookData.imageUrl}
                          alt="Book Img"
                        /> :
                        <img
                          src={require("../../../assets/img/books.png").default}
                          alt="Book Img"
                        />
                      }
                      {/* <i className="fa fa-pencil"></i> */}
                      <br />
                    </InputLabel>

                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      className="ModalFormRow uploadProfileGrid"
                    >
                      {/* <GridItem xs={4} className="order-2">
                        <InputLabel className="uploadProfile">
                          <Input
                            type="file"
                            style={{ display: "none" }}
                            multiple
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={(e) => this._handleFileChange(e)}
                          />
                          {bookData && bookData.imageUrl?.trim().length
                            ? <img
                              src={bookData.imageUrl}
                              alt="Book Img"
                            /> :
                            <img
                              src={require("../../../assets/img/books.png").default}
                              alt="Book Img"
                            />
                          }
                          <br /><br />
                          {errors && (
                            <small
                              className='validation-error'
                            >
                              {errors.imageUrl}
                            </small>
                          )}
                        </InputLabel>

                      </GridItem> */}
                      <GridItem xs={12} className="order-2" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 8 }}>
                        <InputLabel className="uploadProfile">
                          <Input
                            type="file"
                            style={{ display: "none" }}
                            multiple
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={(e) => this._handleFileChange(e, '1')}
                          />
                          {bookData && bookData.imageUrl2?.trim().length
                            ? <img
                              src={bookData.imageUrl2}
                              alt="Book Img"
                            /> :
                            <img
                              src={require("../../../assets/img/books.png").default}
                              alt="Book Img"
                            />
                          }
                          <br /><br />
                        </InputLabel>

                        <InputLabel className="uploadProfile">
                          <Input
                            type="file"
                            style={{ display: "none" }}
                            multiple
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={(e) => this._handleFileChange(e, '2')}
                          />
                          {bookData && bookData.imageUrl3?.trim().length
                            ? <img
                              src={bookData.imageUrl3}
                              alt="Book Img"
                            /> :
                            <img
                              src={require("../../../assets/img/books.png").default}
                              alt="Book Img"
                            />
                          }
                          <br /><br />
                        </InputLabel>
                      </GridItem>
                    </Grid>
                  </GridItem>
                </Grid>

                {/* ======================================= 
                =============== Author's Chips ============
                 =========================================== */}
                <div class="formAuthorChips">
                  {authors && authors.map((each, index) => {
                    return (
                      <Chip
                        key={index}
                        label={each}
                        onDelete={() => this.handleDelete(each)}
                        color="primary"
                      />
                    )
                  })}
                </div>

                {/* ==========================================
                 ============ Author's and Condition ========= 
                 ============================================== */}
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className="ModalFormRow"
                >
                  <GridItem xs={12} sm={6}>
                    <TextField label="Author(s) Name"
                      value={bookData.author}
                      onChange={(e) => this._handleOnChange("author", e.target.value)}
                      onKeyUp={(e) => this._addAuthor(e)}
                      onBlur={(e) => this._addAuthor(e, "Tab")}
                    />
                    {errors && (
                      <small
                        className='validation-error'
                      >
                        {errors.author}
                      </small>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Condition
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={bookData.condition}
                        onChange={(e) => this._handleOnChange("condition", e.target.value)}
                      >
                        <MenuItem value="New">New</MenuItem>
                        <MenuItem value="Good">Good</MenuItem>
                        <MenuItem value="Fair">Fair</MenuItem>
                        <MenuItem value="Poor">Poor</MenuItem>
                      </Select>
                      {errors && (
                        <small
                          className='validation-error'
                        >
                          {errors.condition}
                        </small>
                      )}
                    </FormControl>
                  </GridItem>
                </Grid>
                {/* ===============================================
                ========= Swap - Owner - Price Checkbox ===========
                =================================================== */}
                <FormGroup>
                  <FormControl component="fieldset" margin="none">
                    <FormLabel component="legend" style={{ marginBottom: 5 }}>
                      I want to
                    </FormLabel>
                    <RadioGroup
                      aria-label="Swap"
                      row
                      style={{ marginTop: 0 }}
                      value={bookData.bookMode}
                      onChange={(e) => this._handleOnChange("bookMode", e.target.value)}
                    >
                      <FormControlLabel
                        value="Swap"
                        control={<Radio name="swap" color="primary" />}
                        label="Swap"
                      />
                      <FormControlLabel
                        value="Sell"
                        control={<Radio name="Sell" color="primary" />}
                        label="Sell"
                      />
                      <FormControlLabel
                        value="Both"
                        control={<Radio name="both" color="primary" />}
                        label="Both"
                      />
                      <FormControlLabel
                        value="Proud Owner"
                        control={<Radio name="owner" color="primary" />}
                        label="Proud Owner"
                      />
                    </RadioGroup>
                    {errors && (
                      <small
                        className='validation-error'
                      >
                        {errors.bookMode}
                      </small>
                    )}
                  </FormControl>
                </FormGroup>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className="ModalFormRow"
                >
                  <GridItem xs={12} sm={6}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label2">
                        Genre / Topic
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select2"
                        value={bookData.topic}
                        onChange={(e) => this._handleOnChange("topic", e.target.value)}
                      >
                        {settings?.topics?.length && settings.topics.map((each) => {
                          return (
                            <MenuItem value={each}>{each}</MenuItem>
                          )
                        })}
                      </Select>
                      {errors && (
                        <small
                          className='validation-error'
                        >
                          {errors.topic}
                        </small>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label1">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label1"
                        id="demo-simple-select1"
                        value={bookData.category}
                        onChange={(e) => this._handleOnChange("category", e.target.value)}
                      >
                        {settings?.categories?.length && settings.categories.map((each) => {
                          return (
                            <MenuItem value={each}>{each}</MenuItem>
                          )
                        })}
                      </Select>
                      {errors && (
                        <small
                          className='validation-error'
                        >
                          {errors.category}
                        </small>
                      )}
                    </FormControl>
                  </GridItem>
                </Grid>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className="ModalFormRow"
                >
                  <GridItem xs={12} sm={6}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label2">
                        Languages
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select2"
                        value={bookData.language}
                        onChange={(e) => this._handleOnChange("language", e.target.value)}
                      >
                        {settings?.languages?.length && settings.languages.map((each) => {
                          return (
                            <MenuItem value={each}>{each}</MenuItem>
                          )
                        })}
                      </Select>
                      {errors && (
                        <small
                          className='validation-error'
                        >
                          {errors.language}
                        </small>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} className={invisible === false ? "d-block" : "d-none"}>
                    <TextField label="Author(s) Name"
                      label="Price"
                      value={bookData.price}
                      onChange={(e) => this._handleOnChange("price", e.target.value)}
                      type={invisible === false ? "text" : "hidden"}
                    />
                    {errors && (
                      <small
                        className='validation-error'
                      >
                        {errors.price}
                      </small>
                    )}
                  </GridItem>
                </Grid>
              </form>
            </CardBody>
            <CardFooter className="card-footer">
              <Button className="closeBtn" onClick={() => this._closeModal()}>
                Close
              </Button>
              <Button
                className="addBtnModal"
                onClick={(e) => this._addBook(e)}
              >{isEditable ? "Update Book" : "Add Book"}</Button>
            </CardFooter>
          </Card>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBookShelfModal);
