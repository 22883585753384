/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";

import styles from "../../../../../assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white,
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white,
  });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left + " footer-left"}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <span>© 2021 BookStreet.</span>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link onClick={() => window.open("http://bookstreet.co.in/terms-and-condition/")} className={block + " text-secondary"}>
                Terms
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link onClick={() => window.open("http://bookstreet.co.in/privacy-policy/")} className={block + " text-secondary"}>
                Privacy
              </Link>
            </ListItem>
          </List>
        </div>
        <p className={classes.right + " footer-right"}>
          <span className="poweredBy small">
            Powered By
            <Link onClick={() => window.open("https://www.logic-square.com/")} target="_blank">
              Logic Square
            </Link>
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};
