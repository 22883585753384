import React, { Component } from "react";
import CustomDateRangePicker from "../../general/components/dateRangePicker";
import moment from "moment";
// material-ui components
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import Box from '@material-ui/core/Box';
import Card from "../../general/components/theme-components/Card/Card";
import CardBody from "../../general/components/theme-components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "@material-ui/core";

import UserBookShelf from "./UserBookShelf";
import ViewDetailModal from "./ViewDetailModal";
import { getTransactions } from "../../../http/http-calls";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { ToastsStore } from "react-toasts";
import Paginate from "../../general/components/pagination";

class MyTransactions extends Component {

  state = {
    viewDetailModal: {
      isOpen: false,
      data: null,
    },
    search: "",
    filters: {
      type: "All",
      dateRange: {
        startDate: null,
        endDate: null,
        focusedInput: null,
        startDateId: "startDate",
        endDateId: "endDate"
      }
    },
    transactions: [],
    totalPages: "",
    currentPage: 1,
    totalCount: "",
    userBookShelf: {
      isOpen: false,
      userId: null
    },
  }

  componentDidMount() {
    this._getTransactionsList();
  }

  _getTransactionsList = () => {
    this.props.showLoader();
    const { search, filters } = this.state;
    const payload = {
      filters: {},
      pageNumber: this.state.currentPage
    }
    if (search !== undefined && search !== "") {
      payload.search = search
    }
    if (filters.type !== undefined && filters.type !== "" && filters.type !== "All") {
      payload.filters.type = filters.type
    }
    if (filters.dateRange.startDate && filters.dateRange.endDate) {
      payload.filters.dateRange = {
        from: moment(filters.dateRange.startDate).format('DD/MM/YYYY'),
        to: moment(filters.dateRange.endDate).format('DD/MM/YYYY')
      }
    }
    getTransactions(payload).then((resp) => {
      // console.log("List of transactions here :- ", resp);
      this.setState({
        transactions: resp.transactions,
        currentPage: resp.currentPage,
        totalPages: resp.totalPages,
        totalCount: resp.totalCount,
      });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Failed to load transactions :- " + err.reason);
    });
  }

  _onToggleViewDetailModal = (data) => {
    let { viewDetailModal } = JSON.parse(JSON.stringify(this.state));
    viewDetailModal.isOpen = !viewDetailModal.isOpen;
    if (data !== undefined) {
      viewDetailModal.data = data;
    } else {
      viewDetailModal.data = null;
    }
    this.setState({
      viewDetailModal,
    });
  }

  _handleOnChange = (field, value) => {
    let { search, filters } = this.state;
    if (field === "Search") {
      search = value;
      this.setState({ search }, () => {
        this._getTransactionsList()
      });
    } else {
      if (value !== undefined) {
        filters[field] = value;
      } else {
        filters[field] = "";
      }
      this.setState({ filters }, () => {
        this._getTransactionsList()
      });
    }
  }

  _onDatesChange = (startDate, endDate) => {
    const { filters } = this.state;
    filters.dateRange.startDate = startDate;
    filters.dateRange.endDate = endDate;
    this.setState({ filters });
    if (filters.dateRange.startDate && filters.dateRange.endDate) {
      this._getTransactionsList();
    }
  };

  _onFocusChange = input => {
    const { filters } = this.state;
    filters.dateRange.focusedInput = input;
    this.setState({ filters });
  };

  _clearSuggestions = () => {
    this.setState({
      search: "",
      filters: {
        type: "All",
        dateRange: {
          startDate: null,
          endDate: null,
          focusedInput: null,
          startDateId: "startDate",
          endDateId: "endDate"
        }
      }
    }, () => {
      this._getTransactionsList();
    });
  }

  _showTransactionsList = () => {
    const { transactions } = this.state;
    return transactions && transactions.map((transaction) => {
      return (
        <>
          <TableRow key={transaction._id}>
            <TableCell>{transaction._requestedBook.title}</TableCell>
            <TableCell>{transaction.isSwap ? "Swap" : "Sell"}</TableCell>
            <TableCell
              onClick={() => transaction.isSwap ? this._onToggleViewDetailModal(transaction._swapBook?._id)
                : console.log()
              }
            ><Link className="bookOffered">{transaction.isSwap ? transaction._swapBook?.title : "-"}
              </Link>
            </TableCell>
            <TableCell>{moment(transaction.updatedAt).format("DD MMM YYYY")}</TableCell>
            <TableCell>{transaction._from.name.full}</TableCell>
          </TableRow>
        </>
      )
    });
  }

  _handleUserBookShelf = (id) => {
    this.setState({
      userBookShelf: {
        isOpen: true,
        userId: id
      }
    });
  }

  _closeUserBookShelf = () => {
    this.setState({
      userBookShelf: {
        isOpen: false,
        userId: null
      },
      viewDetailModal: {
        isOpen: false,
        data: null,
      }
    })
  }

  _paginatePage = (pageNumber) => {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this._getTransactionsList();
    });
  };

  _getFirstElement = () => {
    return this.state.transactions.length > 0 ? (this.state.currentPage * 10 - 9) : 0;
  }

  _getLastElement = () => {
    return this.state.transactions.length === 10 ? this.state.currentPage * 10 : this.state.totalCount;
  }

  render() {
    const { transactions, search, filters, totalCount, userBookShelf } = this.state;
    return (
      <>
        {!userBookShelf?.isOpen && (
          <div className="content">
            <div className="pgTitleWrap justify-content-between">
              <h4 className="pageTitle">My Transactions</h4>
            </div>

            {/* ======================================
                -=========== My Request Sent =============
              ========================================== */}

            <Box component="div"
              className="ModalFormRow my-requests-form customFilterWrap"
            >
              <Box component="div" className="searchBook">
                <form autoComplete="off">
                  <label>
                    Book Name
                  </label>

                  <Box component="div" className="search-input">
                    <TextField
                      type="text"
                      autoComplete="current-text"
                      variant="outlined"
                      value={search}
                      onChange={(e) => this._handleOnChange("Search", e.target.value)}
                    />
                  </Box>
                </form>
              </Box>
              <Box component="div" className="searchBook">
                <form autoComplete="off">
                  <label>
                    Type
                  </label>

                  <Box component="div" className="filter-input">
                    <FormControl variant="outlined" className="m-0">
                      <Select
                        labelId="demo-simple-select-autowidth-label2"
                        value={filters.type}
                        onChange={(e) => this._handleOnChange("type", e.target.value)}
                      >
                        <MenuItem value="All">
                          <em>All</em>
                        </MenuItem>
                        <MenuItem value="Swap">Swap</MenuItem>
                        <MenuItem value="Sell">Sell</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </form>
              </Box>

              <Box component="div" className="searchBook">
                <form autoComplete="off">
                  <FormControl variant="outlined" className="m-0">
                    <label>Date Range</label>
                    <CustomDateRangePicker
                      dateRange={this.state.filters.dateRange}
                      onDatesChange={this._onDatesChange}
                      onFocusChange={this._onFocusChange}
                    />
                  </FormControl>
                </form>
              </Box>
            </Box>

            <Box component="div" className="clear-all clear-all-custom" onClick={() => this._clearSuggestions()}>
              Clear All
              <i className="fa fa-refresh"></i>
            </Box>

            {/* ================== Table ===============*/}
            <Card className="bookShellCard bookShellModal bookTableCard">
              <CardBody className="w-100 h-100">
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Book</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Book Offered</TableCell>
                        <TableCell>Closure Date</TableCell>
                        <TableCell>Requestor</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactions.length ? this._showTransactionsList()
                        :
                        <TableRow>
                          <TableCell align="center" colspan="4">
                            No transaction to show
                          </TableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>

            {/* ============== Load More =============*/}
            <Box component="div" className="paginationWrap">
              <span className="poweredBy">
                Showing  <b>{this._getFirstElement()}</b> to <b>{this._getLastElement()}</b> out of <b>{totalCount}</b>
              </span>
              <div>
                <Paginate
                  totalPages={this.state.totalPages}
                  currentPage={this.state.currentPage}
                  paginate={this._paginatePage}
                ></Paginate>
              </div>
            </Box>
            <ViewDetailModal
              isOpen={this.state.viewDetailModal.isOpen}
              creatorData={this.state.viewDetailModal.data}
              toggle={() => this._onToggleViewDetailModal()}
              toUserBookShelf={(id) => this._handleUserBookShelf(id)}
              reload={() => this._getTransactionsList()}
            ></ViewDetailModal>
          </div>
        )}


        {userBookShelf.isOpen &&
          <UserBookShelf
            isOpen={userBookShelf.isOpen}
            data={userBookShelf.userId}
            toggle={() => this._closeUserBookShelf()}
          ></UserBookShelf>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
    userData: state.userData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTransactions);