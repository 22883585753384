import React, { Component } from "react";

// material-ui components
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

class LoginPage extends Component {
  // preventDefault = (event) => event.preventDefault();

  render() {
    return (
      <>
        <Grid container direction="row" justify="center" className="public-footer login-footer-links">
          <GridItem xs={12} sm={7}>
            <span>© 2021 BookStreet.</span>
            <Link onClick={() => window.open("http://bookstreet.co.in/terms-and-condition/")} className="terms">
              Terms
            </Link>
            <Link onClick={() => window.open("http://bookstreet.co.in/privacy-policy/")}>
              Privacy
            </Link>
          </GridItem>
          <GridItem xs={12} sm={5}>
            <span className="poweredBy">
              Powered By
              <Link onClick={() => window.open("https://www.logic-square.com/")} target="_blank">
                Logic Square
              </Link>
            </span>
          </GridItem>
        </Grid>
      </>
    );
  }
}

export default LoginPage;
