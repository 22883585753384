import React, { Component } from "react";

// material-ui components
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import Box from '@material-ui/core/Box';
import Card from "../../general/components/theme-components/Card/Card";
import CardBody from "../../general/components/theme-components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import ClearAllIcon from '@material-ui/icons/ClearAll';

import ViewDetailModal from "./ViewDetailModal";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { getOtherUserBooks, getOtherUser } from "../../../http/http-calls";
import { ToastsStore } from "react-toasts";

class UserBookShelf extends Component {

  state = {
    viewDetailModal: {
      isOpen: false,
      data: null,
    },
    books: [],
    username: ""
  };

  componentDidMount() {
    this._getUserBooks();
    this._getUserInfo();
  }

  _getUserBooks = () => {
    this.props.showLoader();
    getOtherUserBooks(this.props.data).then((resp) => {
      // console.log("Other User's Book here :- ", resp);
      this.setState({
        books: resp.books
      });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Failed to load book details :- " + err.reason);
    });
  }

  _getUserInfo = () => {
    getOtherUser(this.props.data).then((resp) => {
      this.setState({
        username: resp.user.username
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  _onToggleViewDetailModal = (data) => {
    let { viewDetailModal } = JSON.parse(JSON.stringify(this.state));
    viewDetailModal.isOpen = !viewDetailModal.isOpen;
    if (data !== undefined) {
      viewDetailModal.data = data;
    } else {
      viewDetailModal.data = null;
    }
    this.setState({
      viewDetailModal,
    });
  }

  render() {
    const { books, username } = this.state;
    return (
      <>
        <div className="userBookShelf">
          <div className="pgTitleWrap" style={{ justifyContent: 'flex-start' }}>
            <Box component="div" className="backBtn">
              <span className="fa fa-chevron-left"
                onClick={() => this.props.toggle()}
              />
            </Box>
            <h4 className="pageTitle">{`${username}'s`} BookShelf</h4>
          </div>
          {/* ============ Back Button =============*/}
          <Grid
            container
            direction="row"
            className="my-book-shelf"
          >
            {books?.length ?
              books.map((book) => {
                return (
                  <GridItem xs={12} sm={6} className="my-book-shelf-grid-item">
                    <Card className="bookShellCardviewDetailHover bookShellCard" onClick={() => this._onToggleViewDetailModal(book._id)}>
                      <Grid direction="row" justify="center" className="bookShellCardDefault">
                        <GridItem
                          xs={12}
                          lg={5}
                          spacing={1}
                          className="card-price-container bookShellCard-grid-item"
                          style={{ padding: "0px !important" }}
                        >
                          <img
                            src={book.imageUrl[0]}
                            alt=""
                            className="card-img"
                          />
                          <p className="card-price">{book.bookStatusMode === "Swap" ? book.bookStatusMode
                            : book.bookStatusMode === "Proud Owner" ? book.bookStatusMode : `Sell: ₹${book.price}`}
                          </p>
                        </GridItem>
                        <GridItem
                          xs={12}
                          lg={7}
                          spacing={1}
                          className="bookShellCard-grid-item"
                        >
                          <CardBody className="w-100 h-100">
                            <h4 className="card-heading">
                              {book.title}
                            </h4>
                            <h6 className="card-author">Author: {book?.author?.length && book.author.toString()} </h6>

                            <hr />
                            <p className="card-para">
                              {book.description}
                            </p>

                            {/* =========== Category & Location ============= */}
                            {/* <Grid direction="row" justify="space-between" className="category-location">
                            <GridItem
                              xs={5}
                              spacing={1}
                              className="bookShellCard-grid-item"
                            >
                              <span className="bookShellCard-category fs-12">Category: {book.category.toString()}</span>
                            </GridItem>

                            <GridItem
                              xs={5}
                              spacing={1}
                              className="bookShellCard-grid-item"
                            >
                              <span className="bookShellCard-location fs-12">Condition: {book.condition}</span>
                            </GridItem>
                          </Grid> */}

                            <div className="category-location">
                              <span className="bookShellCard-category fs-12">Category: <span>{book.category.toString()}</span></span>
                              <span className="bookShellCard-location fs-12">Condition: <span>{book.condition}</span></span>
                            </div>

                            {book.bookStatusMode !== "Proud Owner" &&
                              <div className="card-button-container">
                                {book?.bookStatusMode === "Both" ?
                                  <>
                                    <Button className="swap" round>
                                      Swap
                                    </Button>
                                    <Button className="sale" round>
                                      Buy
                                    </Button>
                                  </>
                                  : book.bookStatusMode === "Swap" ?
                                    <Button className="swap" round>
                                      Swap
                                    </Button>
                                    : <Button className="sale" round>
                                      Buy
                                    </Button>
                                }
                              </div>
                            }
                          </CardBody>
                        </GridItem>
                      </Grid>
                    </Card>
                  </GridItem>
                )
              })
              : <div style={{ textAlign: "center", width: "inherit" }}><h5>No books to show</h5></div>
            }
          </Grid>
        </div>
        <ViewDetailModal
          isOpen={this.state.viewDetailModal.isOpen}
          creatorData={this.state.viewDetailModal.data}
          toggle={() => this._onToggleViewDetailModal()}
          toUserBookShelf={() => console.log("In user Bookshelf :>>")}
          reload={() => this._getUserBooks()}
        ></ViewDetailModal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBookShelf);