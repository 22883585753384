import React, { Component } from "react";

// material-ui components
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import LoginFooter from "../component/login-footer";
import { Link } from "react-router-dom";
import { login, register, checkUsername, getIpData, addDevice } from "../../../http/http-calls";
import DeviceMetaData from "../../../device-metadata/index";
import OneSignalHelper from "../../../onesignal-helper/index";
import { ToastsStore } from "react-toasts";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { updateUserData } from "../../../redux/actions/user-data";
import { connect } from "react-redux";

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      user: {
        name: "",
        email: "",
        username: "",
        password: "",
        occupation: "",
        pincode: "",
        phone: "",
      },
      isDirty: {
        name: false,
        email: false,
        username: false,
        password: false,
        occupation: false,
        pincode: false,
        phone: false,
      },
      errors: {},
      isAvail: false,
      isVisible: false
    };
  }

  componentDidMount() {
    document.getElementById("adornment-name").tabIndex = "1"
    document.getElementById("adornment-email").tabIndex = "3"
    document.getElementById("adornment-username").tabIndex = "2"
    document.getElementById("adornment-password").tabIndex = "4"
    document.getElementById("adornment-phone").tabIndex = "5"
    document.getElementById("adornment-pincode").tabIndex = "6"
    document.getElementById("adornment-occupation").tabIndex = "7"
  }

  /**
   * _handleOnChange
   * To handle input on change and set the values to state
   */

  _handleOnChange = ({ currentTarget }) => {
    const { user, isDirty } = this.state;
    user[currentTarget.name] = currentTarget.value;
    isDirty[currentTarget.name] = true;
    this.setState({ user });
    this._validateForm();
  };

  /**
   * To Validate the form and show the error messages
   */
  _validateForm() {
    const { user, errors, isDirty } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === "email" && isDirty.email) {
        if (!user.email.trim().length) {
          errors.email = "*Required";
        } else if (
          user.email.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(user.email)
        ) {
          errors.email = "Invalid Email";
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      } else if (each === "password" && isDirty.password) {
        if (!user.password.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.password = false;
        }
      }
       else if (each === "name" && isDirty.name) {
        if (!user.name.trim().length) {
          // console.log(admin.password);
          errors[each] = "*Required";
        } else if (user.name.trim().length
        && !user.name.match(/^[a-zA-Z ]*$/)
      ) {
        errors[each] = "Invalid name format";
      } else if (user.name.trim().length
        && user.name.match(/^[a-zA-Z ]*$/)
        && user.name.length < 3
      ) {
        errors[each] = "Name must be of 3 or more characters";
      } else {
          delete errors[each];
          isDirty.name = false;
        }
      } else if (each === "phone" && isDirty.phone) {
        if (!user.phone.trim().length) {
          errors[each] = "*Required";
        } else if (user.phone.trim().length
          && !user.phone.match(/^[0-9]{10}$/)
        ) {
          errors[each] = "Enter valid phone number";
        } else {
          delete errors[each];
          isDirty.phone = false;
        }
      } else if (each === "occupation" && isDirty.occupation) {
        if (!user.occupation.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.occupation = false;
        }
      } else if (each === "username" && isDirty.username) {
        if (!user.username.trim().length) {
          errors[each] = "*Required";
          this.setState({ isAvail: false });
        } else if (user.username.trim().length) {
          let obj = {
            username: user.username,
          };
          this._checkUsername(obj);
        } else {
          delete errors[each];
          isDirty.username = false;
        }
      } else if (each === "pincode" && isDirty.pincode) {
        if (!user.pincode.toString().trim().length) {
          errors[each] = "*Required";
        } else if (user.pincode.toString().trim().length
          && !user.pincode.toString().match(/^[0-9]{6}$/)) {
          errors[each] = "Enter valid pincode";
        } else {
          delete errors[each];
          isDirty.pincode = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  /**
   * To handle submit of the form and validate it
   */
  _handleOnSubmit = (event) => {
    event.preventDefault();
    const { isDirty } = this.state;
    isDirty.name = true;
    isDirty.email = true;
    isDirty.phone = true;
    isDirty.username = true;
    isDirty.password = true;
    isDirty.occupation = true;
    isDirty.pincode = true;
    this.setState({ isDirty });
    let errors = this._validateForm();
    // console.log(errors);
    // this.setState({errors});
    if (!errors) {
      // console.log("Make API call");
      this._registerUser();
    }
  };

  _checkUsername = (username) => {
    let { errors, isAvail } = this.state;
    checkUsername(username).then((resonse) => {
      if (resonse.isAvailable) {
        isAvail = true;
        delete errors.username;
      } else {
        isAvail = false;
        errors.username = "Enter a unique username";
      }
      this.setState({ errors, isAvail });
    });
  };

  _registerUser = async () => {
    const { user } = this.state;
    const obj = {
      email: user.email,
      name: {
        first: user.name.split(" ")[0],
        last: user.name.split(" ")[1] ? user.name.split(" ")[1] : "",
      },
      occupation: user.occupation,
      password: user.password,
      phone: user.phone,
      address: { pincode: user.pincode },
      username: user.username,
    };
    register(obj).then(
      (response) => {
        // console.log(response);
        ToastsStore.success(" Successfully Registered ");
        this.props.showLoader();
        login({
          handle: user.email,
          password: user.password,
        }).then(
          async (response) => {
            // console.log(response);
            this.props.updateUserData(response);
            const deviceData = await this._getDeviceDetails();
            addDevice({ deviceId: deviceData.deviceId }).then((resp) => {
              // console.log("Add device response here :- ", resp);
            }).catch((err) => {
              console.log("error : ", err);
            });
            this.props.hideLoader();
            this.props.history.push("/home");
          },
          (error) => {
            // console.log("error :", error);
            this.props.hideLoader();
            ToastsStore.error(error.reason);
          }
        );
      },
      (error) => {
        // console.log("error :", error);
        ToastsStore.error(error.reason);
      }
    );
  };

  _getDeviceDetails = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const deviceDetails = {};
        deviceDetails["name"] =
          DeviceMetaData.getBrowser() + " on " + DeviceMetaData.getOs();
        deviceDetails["ipAddress"] = (await getIpData())["ip"];
        // Get device id
        // First try to get from one signal
        let onesignalDeviceId = null;
        onesignalDeviceId = await OneSignalHelper.getDeviceId();
        if (onesignalDeviceId) {
          deviceDetails["allowNotification"] = true;
          deviceDetails["deviceId"] = onesignalDeviceId;
        } else {
          deviceDetails["allowNotification"] = false;
          deviceDetails["deviceId"] = DeviceMetaData.getDeviceId();
        }
        resolve(deviceDetails);
      } catch (error) {
        reject(error);
      }
    });
  };

  _handleVisibleToggle = () => {
    let { isVisible } = this.state;
    isVisible = !isVisible;
    this.setState({ isVisible });
  };

  render() {
    const { user, errors, isAvail } = this.state;
    return (
      <>
        <div className="loginPage registerPage">
          <Card>
            <CardContent className="h-100">
              <Grid
                container
                direction="row"
                justify="center"
                className="h-100"
              >
                <GridItem md="4" className="pl-0 h-100  d-md-none">
                  <img
                    src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=334&q=80"
                    className="fit-img"
                    alt=""
                  />
                </GridItem>
                <GridItem
                  md="8"
                  className="d-flex flex-column justify-content-between pr-0 h-100 w-100"
                >
                  <div className="d-flex align-items-center justify-content-center flex-column  h-90">
                    <img
                      className="logoImg"
                      src={require("../../../assets/img/logo.png").default}
                      alt=""
                    />
                    <div className="w-100">
                      <Typography
                        component="h5"
                        variant="h5"
                        className="m-0 text-center"
                      >
                        Create Account
                      </Typography>
                      
                  </div>
                      <form
                        noValidate
                        className="loginForm"
                        onSubmit={this._handleOnSubmit}
                      >
                       
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          className="margin-top"
                        >
                          <GridItem md="6" xs="12">
                            <FormControl> 
                              <Input
                                id="adornment-name"
                                name="name"
                                placeholder="Name"
                                value={user.name}
                                onChange={this._handleOnChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <i class="fa fa-user"></i>
                                  </InputAdornment>
                                }
                              />
                              {errors && (
                                <small className="validation-error">
                                  {errors.name}
                                </small>
                              )}
                            </FormControl>
                          </GridItem>
                          <GridItem md="6" xs="12">
                            <FormControl>
                              <Input
                                id="adornment-username"
                                placeholder="Username"
                                name="username"
                                value={user.username}
                                onChange={this._handleOnChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <i class="fa fa-user"></i>
                                  </InputAdornment>
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <span className="input-group-text">
                                      <i
                                        className={
                                          isAvail
                                            ? "fa fa-check-circle"
                                            : "fa fa-times-circle"
                                        }
                                      ></i>
                                    </span>
                                  </InputAdornment>
                                }
                              />
                              {errors && (
                                <small className="validation-error">
                                  {errors.username}
                                </small>
                              )}
                            </FormControl>
                          </GridItem>
                        </Grid>

                        <Grid
                          container
                          direction="row"
                          justify="center"
                          className="margin-top"
                        >
                          <GridItem xs="12">
                            <FormControl>
                              <Input
                                id="adornment-email"
                                placeholder="Email"
                                name="email"
                                value={user.email}
                                onChange={this._handleOnChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <i className="fa fa-envelope"></i>
                                  </InputAdornment>
                                }
                              />
                              {errors && (
                                <small className="validation-error">
                                  {errors.email}
                                </small>
                              )}
                            </FormControl>
                          </GridItem>
                          </Grid>
                        
                        

                        <Grid
                          container
                          direction="row"
                          justify="center"
                          className="margin-top"
                        >
                          <GridItem md="6" xs="12">
                            <FormControl>
                              <Input
                                id="adornment-password"
                                placeholder="Password"
                                name="password"
                                type={this.state?.isVisible ? "text" : "password"}
                                value={user.password}
                                onChange={this._handleOnChange}
                                inputProps={{autoComplete: "new-password"}}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <i className="fa fa-lock"></i>
                                  </InputAdornment>
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <span
                                      className="input-group-text"
                                      onClick={() =>
                                        this._handleVisibleToggle()
                                      }
                                    >
                                      <i
                                        className={
                                          this.state.isVisible
                                            ? "fa fa-eye showPassword"
                                            : "fa fa-eye-slash showPassword"
                                        }
                                      ></i>
                                    </span>
                                  </InputAdornment>
                                }
                              />
                              {errors && (
                                <small className="validation-error">
                                  {errors.password}
                                </small>
                              )}
                            </FormControl>
                          </GridItem>
                          <GridItem md="6" xs="12">
                            <FormControl>
                              <Input
                                id="adornment-phone"
                                placeholder="Phone"
                                name="phone"
                                value={user.phone}
                                type="text"
                                onChange={this._handleOnChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <i class="fa fa-phone"></i>
                                  </InputAdornment>
                                }
                              />
                              {errors && (
                                <small className="validation-error">
                                  {errors.phone}
                                </small>
                              )}
                            </FormControl>
                          </GridItem>
                        </Grid>
                        
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          className="margin-top"
                        >
                          <GridItem md="6" xs="12">
                             <FormControl>
                              <Input
                                id="adornment-pincode"
                                name="pincode"
                                type="text"
                                placeholder="Pincode"
                                value={user.pincode}
                                onChange={this._handleOnChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <i class="fa fa-map-marker"></i>
                                  </InputAdornment>
                                }
                              />
                              {errors && (
                                <small className="validation-error">
                                  {errors.pincode}
                                </small>
                              )}
                            </FormControl>
                          </GridItem>
                          <GridItem md="6" xs="12">
                           <FormControl>
                            <Input
                              id="adornment-occupation"
                              name="occupation"
                              placeholder="Occupation"
                              value={user.occupation}
                              onChange={this._handleOnChange}
                              startAdornment={
                                <InputAdornment position="start">
                                  <i class="fa fa-briefcase"></i>
                                </InputAdornment>
                              }
                            />
                            {errors && (
                              <small className="validation-error">
                                {errors.occupation}
                              </small>
                            )}
                          </FormControl>
                          </GridItem>
                        </Grid>

                        {/* ====== SignUp Button ====== */}

                        <FormControl className="loginButton margin-top">
                          <Button
                            className="formSubmit bg-theme fs-15"
                            type="submit"
                          >
                            Sign up
                          </Button>
                        </FormControl>
                      </form>

                      <Typography
                        component="h6"
                        className="m-0 text-center text-secondary fs-15 fw-400"
                      >
                        <Link to="/login" className="text-theme ml-1">
                          Back to Login
                        </Link>
                      </Typography>
                    </div>

                  <LoginFooter/>
                </GridItem>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserData: (userData) => dispatch(updateUserData(userData)),
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
