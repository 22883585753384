import React from "react";
import { makeStyles } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(() => ({
  paginator: {
    justifyContent: "center",
    paddingTop: "20px"
  }
}));

export const Paginate = ({ currentPage, totalPages, paginate }) => {

  const classes = useStyles();

  const handleChange = (event, value) => {
      paginate(value);
  }

  return (
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={handleChange}
      defaultPage={1}
      color="primary"
      size="large"
      showFirstButton
      showLastButton
      classes={{ ul: classes.paginator }}
    />
  );
};

export default Paginate;
