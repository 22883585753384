import React, { Component } from "react";

// material-ui components
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";
import LoginFooter from "../component/login-footer";
// import Link from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import { login, getIpData, addDevice } from "../../../http/http-calls";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { updateUserData } from "../../../redux/actions/user-data"
import { ToastsStore } from "react-toasts";
import DeviceMetaData from "../../../device-metadata/index";
import OneSignalHelper from "../../../onesignal-helper/index";

class LoginPage extends Component {
  state = {
    user: {
      email: "",
      password: "",
    },
    isDirty: {
      email: false,
      password: false,
    },
    errors: {},
    loading: false,
    isVisible: false,
  };

  componentDidMount() {
    this._initializeOneSignal();
  }

  _initializeOneSignal = async () => {
    const hasPermission = await OneSignalHelper.getNotificationPermission();
    // console.log("Permission here :- ", hasPermission);
    if (!hasPermission) {
      OneSignalHelper.showPrompt();
    }
  };

  /**
   * _handleOnChange
   * To handle input on change and set the values to state
   */
   _handleOnChange = (field, value) => {
    const { user, isDirty } = this.state;
    user[field] = value;
    isDirty[field] = true;
    this.setState({ user, isDirty }, () => {
      this._validateForm();
    });
  };

  /**
   * To Validate the form and show the error messages
   */
  _validateForm() {
    const { user, errors, isDirty } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === "email" && isDirty.email) {
        if (!user.email.trim().length) {
          errors.email = "*Required";
        } else if (
          user.email.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(user.email)
        ) {
          errors.email = "Invalid Email";
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      } else if (each === "password" && isDirty.password) {
        if (!user.password.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.password = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  /**
   * To handle submit of the form and validate it
   */
   _handleOnSubmit = (e) => {
    e.preventDefault();
    let isDirty = {
      email: true,
      password: true,
    };
    this.setState({ isDirty }, () => {
      let errors = this._validateForm();
      // console.log(errors);
      if (!errors) {
        // console.log("Make API call");
        this._login();
      }
    });
  };

  _getDeviceDetails = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const deviceDetails = {};
        deviceDetails["name"] =
          DeviceMetaData.getBrowser() + " on " + DeviceMetaData.getOs();
        deviceDetails["ipAddress"] = (await getIpData())["ip"];
        // Get device id
        // First try to get from one signal
        let onesignalDeviceId = null;
        onesignalDeviceId = await OneSignalHelper.getDeviceId();
        if (onesignalDeviceId) {
          deviceDetails["allowNotification"] = true;
          deviceDetails["deviceId"] = onesignalDeviceId;
        } else {
          deviceDetails["allowNotification"] = false;
          deviceDetails["deviceId"] = DeviceMetaData.getDeviceId();
        }
        resolve(deviceDetails);
      } catch (error) {
        reject(error);
      }
    });
  };

  _login = async () => {
    const { user } = this.state;
    // this.props.showLoader();
    login({
      handle: user.email,
      password: user.password,
    }).then(async (response) => {
      // console.log(response);
      this.props.updateUserData(response);
      this.props.hideLoader();
      ToastsStore.success(" Successfully Logged-In ");
      this.props.history.push("/home");
      OneSignalHelper.getNotificationOpened();
      const deviceData = await this._getDeviceDetails();
      addDevice({ deviceId: deviceData.deviceId }).then((resp) => {
        // console.log("Add device response here :- ", resp);
      }).catch((err) => {
        console.log("error : ", err);
      });
    },
    (error) => {
      // console.log("error :", error);
      this.props.hideLoader(); 
      ToastsStore.error(error.reason);
    }
);
  };

  _handleVisibleToggle = () => {
    let { isVisible } = this.state;
    isVisible = !isVisible;
    this.setState({ isVisible });
  };
  
  render() {
    const { user, errors, isVisible } = this.state;
    return (
      <>
        <div className="loginPage">
          <Card>
            <CardContent className="h-100">
              <Grid
                container
                direction="row"
                justify="center"
                className="h-100"
              >
                <GridItem lg="4" md="5" className="pl-0 h-100 d-md-none">
                  <img
                    src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=334&q=80"
                    className="fit-img"
                    alt=""
                  />
                </GridItem>
                <GridItem
                  lg="8" md="7"
                  className="d-flex flex-column justify-content-between pr-0 h-100 w-100"
                >
                  <div className="d-flex align-items-center justify-content-center flex-column  h-90">
                    <img
                      className="logoImg"
                      src={require("../../../assets/img/logo.png").default}
                      alt=""
                    />
                    <div className="w-100">
                      <Typography
                        component="h5"
                        variant="h5"
                        className="m-0 text-center"
                      >
                        Login
                      </Typography>
                      <form noValidate className="loginForm" onSubmit={this._handleOnSubmit}>
                        <FormGroup>
                          <FormControl>
                            <Input
                              id="adornment-email"
                              placeholder="Email"
                              name='handle'
                              value={user.email}
                              onChange={(e) =>
                                this._handleOnChange("email", e.target.value)
                              }
                              startAdornment={
                                <InputAdornment position="start">
                                  <i className="fa fa-envelope"></i>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {errors && (
                          <small
                            className='validation-error'
                          >
                            {errors.email}
                          </small>
                        )}
                          <FormControl className="margin-top">
                            <Input
                              id="adornment-password"
                              placeholder="Password"
                              type={isVisible ? "text" : "password"}
                              name="password"
                              value={user.password}
                              onChange={(e) =>
                              this._handleOnChange("password", e.target.value)
                              }
                              inputProps={{autoComplete: "new-password"}}
                              startAdornment={
                                <InputAdornment position="start">
                                  <i className="fa fa-lock"></i>
                                </InputAdornment>
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <span className="input-group-text">
                                    <i className={
                                isVisible
                                  ? "fa fa-eye cursorPointer"
                                  : "fa fa-eye-slash cursorPointer"
                              }
                              onClick={() => this._handleVisibleToggle()}></i>
                                  </span>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {errors && (
                          <small
                            className='validation-error'
                          >
                            {errors.password}
                          </small>
                        )}
                          <FormControl className="loginButton">
                              <Button className="formSubmit bg-theme fs-15" type="submit">
                                Login
                              </Button>
                          </FormControl>
                        </FormGroup>
                      </form>

                      <Typography
                        component="h6"
                        className="m-0 text-center text-secondary fs-15 fw-400"
                      >
                        <Link to="/forgot-password" className="text-theme ml-1">
                          Forgot Password
                        </Link>
                      </Typography>
                      <br />
                      <Typography
                        component="h6"
                        className="m-0 text-center text-secondary fs-15"
                      >
                        Don't have an account?
                        <Link to="/register" className="text-theme ml-1 fw-500">
                          Register Here
                        </Link>
                      </Typography>
                    </div>
                  </div>

                  <LoginFooter></LoginFooter>
                </GridItem>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserData: (userData) => dispatch(updateUserData(userData)),
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default  connect(mapStateToProps, mapDispatchToProps)(LoginPage);
