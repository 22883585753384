import React, { Component } from "react";

// material-ui components
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import FormGroup from "@material-ui/core/FormGroup";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MyBookShelfModal: {
        isOpen: false,
        data: null,
      },
      generalQA: [{
        question: "How can we access BookStreet?",
        answer: "BookStreet is a web portal that you can access through your desktop and mobile devices."
      },
      {
        question: "How much is the registration fee?",
        answer: "BookStreet is a free platform, and there is no registration fee to join. To register yourself, please visit here."
      },
      {
        question: "How many books should I list?",
        answer: "On BookStreet, there is no minimum and a maximum number of books for listing. You can add your whole collection to it."
      },
      {
        question: "How can I ask for a swap?",
        answer: "For book swapping, you need to select the book you want to get and contact its registered user and offer a book for swapping. You can contact the user by using the chat services of the platform and connect with the user and coordinate with it for further process."
      },
      {
        question: "How can we access BookStreet?",
        answer: "BookStreet is a web portal that you can access through your desktop and mobile devices."
      },
      {
        question: "Can I buy it from BookStreet?",
        answer: "BookStreet is a user platform. It doesn’t directly engage in any sales or purchases. To buy any book from here, you need to coordinate with the registered user of that book."
      },
      {
        question: "What are all details I need to update while updating the collection?",
        answer: "While Adding the new book to your bookshelf, you need to enter the following details:",
        otherInfo: ["Book Title", "Author", "Language (English is default)", "Category & Genre/Topic", "Images (upto three)", "Book condition (New | Good | Fair | Poor)", "Interested in Swap or sell.", "Price (only if Sell is selected)", "Review (Good | Fair | Bad)", "Additional Information"]
      },
      {
        question: "Why do we need to enter our personal information?",
        answer: "While registering your account, you will be asked to enter your personal information like your phone number and complete address so that other users interested in your book collection can contact you for swapping or purchasing. Your contact information will be hidden for other users until they request your contact information and you approved it. After that, they won’t be able to see it. BookStreet doesn’t sell any user information to third-party companies."
      }],
      search: "",
      renderElement: []
    };

    this._onToggleMyBookShelfModal = this._onToggleMyBookShelfModal.bind(this);
  }

  componentDidMount() {
    this.setState({
      renderElement: this.state.generalQA
    });
  }

  _onToggleMyBookShelfModal() {
    let { MyBookShelfModal } = JSON.parse(JSON.stringify(this.state));
    MyBookShelfModal.isOpen = !MyBookShelfModal.isOpen;
    this.setState({
      MyBookShelfModal,
    });
  }

  _renderQA = (arrayElement) => {
    return arrayElement.length ? arrayElement.map((el, index) => {
      if (el.otherInfo !== undefined) {
        return (
          <>
            <Accordion className="customAccordion" key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{el.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {el.answer}
                </Typography>

                <List className="customList">
                  {el.otherInfo.map((info, position) => {
                    return (
                      <ListItem key={position}>
                        {info}
                      </ListItem>
                    )
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          </>
        )
      } else {
        return (
          <>
            <Accordion className="customAccordion" key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{el.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {el.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>{" "}
          </>
        )
      }
    })
      :
      <Typography align="center" colspan="6">
        No results found
      </Typography>
  }

  _handleOnChange = (value) => {
    let { search } = this.state;
    search = value;
    this.setState({ search });
  }

  _searchKeyword = (e) => {
    e.preventDefault();
    const { search, generalQA } = this.state;
    let { renderElement } = this.state;
    if (search.trim().length) {
      renderElement = [];
      generalQA.forEach((el) => {
        if (el.question.toLowerCase().includes(search.toLowerCase())
          || el.answer.toLowerCase().includes(search.toLowerCase())
          || el.otherInfo?.join(",").toLowerCase().includes(search.toLowerCase())
        ) {
          renderElement.push(el);
        }
      })
      this.setState({ renderElement });
    }
  }

  _clearSuggestion = () => {
    this.setState({
      search: "",
      renderElement: this.state.generalQA
    });
  }

  render() {
    const { renderElement, search } = this.state
    return (
      <>
        <div className="content">
          <h4 className="pageTitle">FAQ</h4>
          <Typography paragraph style={{ marginTop: 20, fontSize: 14 }}>
            Some tips to help you. If you don't find an answer to your issue
            here,{" "}
            <Link className="text-theme" onClick={() => window.open("http://bookstreet.co.in/#contact-us")}>
              write to us
            </Link>
          </Typography>
          <div class="faq-search">
            <form noValidate autoComplete="off" className="searchBarFaqWrap" onSubmit={(e) => this._searchKeyword(e)}>
              <FormGroup row>
                <Button className="clearBtn">
                  <i class="fa fa-times"
                    onClick={() => this._clearSuggestion()}
                  ></i>
                </Button>
                <input
                  placeholder="Type a question or keyword..."
                  className="searchBarFaq form-control"
                  value={search}
                  onChange={(e) => this._handleOnChange(e.target.value)}
                />
                <Button className="searchBtnFaq">
                  <i class="fa fa-search"
                    onClick={(e) => this._searchKeyword(e)}
                  ></i>
                </Button>
              </FormGroup>
            </form>
          </div>

          <h4 className="sectionTitle">General Questions</h4>
          {this._renderQA(renderElement)}
          <br />
        </div>
      </>
    );
  }
}

export default FAQ;
