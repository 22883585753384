/* eslint-disable import/no-anonymous-default-export */
export default {
  // baseURL : "http://localhost:3000/api/v1",
  // baseURL : "http://178.128.127.115:3000/api/v1",

  //live base Url 
  // baseURL : "http://159.89.164.39:3000/api/v1",
  baseURL : "https://api.bookstreet.co.in/api/v1",

  // CHAT_URL : "http://localhost:3000/faye",
  // CHAT_URL : "http://178.128.127.115:3000/faye",

  //live
  // CHAT_URL : "http://159.89.164.39:3000/faye",
  CHAT_URL : "https://api.bookstreet.co.in/faye",

  cloudinaryConfig: {
    cloudName: "manage-my-shaadi",
    uploadPreset: "bvnmnicy",
    folderName: "bookstreet"
  }
};
