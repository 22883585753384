import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
// material-ui components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { getNotifications } from "../../../http/http-calls";
import { ToastsStore } from "react-toasts";
class Notifications extends Component {

  state = {
    notifications: []
  }

  componentDidMount() {
    this._getAllNotifications();
  }

  _getAllNotifications = () => {
    this.props.showLoader();
    getNotifications().then((resp) => {
      // console.log("notifications here :>> ", resp.notifications)
      this.setState({
        notifications: resp.notifications
      });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Failed to load notifications :- " + err.reason);
    })
  }

  _getPath = (title) => {
    let path = {};
    if (title === "New Request") {
      path = {
        pathname: "/my-requests",
        query: { title }
      }
    } else if (title === "New Response") {
      path = {
        pathname: "/my-requests"
      }
    } else {
      path = {
        pathname: "/my-transactions"
      }
    }
    return path;
  }

  _showNotifications = () => {
    const { notifications } = this.state;
    return notifications && notifications.map((notification) => {
      return (
        <>
          <ListItem key={notification._id}>
            <span>{moment(notification.createdAt).format("MMM DD, HH:MM A")}</span>
            <Link to={() => this._getPath(notification.title)}>{notification.description}</Link>
          </ListItem>
        </>
      )
    })
  }

  render() {
    const { notifications } = this.state
    return (
      <>
        <div className="content">
          <div className="pgTitleWrap">
            <h4 className="pageTitle">Notifications</h4>
          </div>
          <List className="notificationsWrap">
            {notifications.length ? this._showNotifications()
              :
              <div align="center">
                No notification to show
              </div>
            }
          </List>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
