// import Buttons from "views/Components/Buttons.js";
// import Calendar from "views/Calendar/Calendar.js";
// import Charts from "views/Charts/Charts.js";
import Dashboard from "./modules/private/pages/Dashboard";
// import ErrorPage from "views/Pages/ErrorPage.js";
// import ExtendedForms from "views/Forms/ExtendedForms.js";
// import ExtendedTables from "views/Tables/ExtendedTables.js";
// import FullScreenMap from "views/Maps/FullScreenMap.js";
// import GoogleMaps from "views/Maps/GoogleMaps.js";
// import GridSystem from "views/Components/GridSystem.js";
// import Icons from "views/Components/Icons.js";
// import LockScreenPage from "views/Pages/LockScreenPage.js";
// import LoginPage from "views/Pages/LoginPage.js";
// import Notifications from "views/Components/Notifications.js";
// import Panels from "views/Components/Panels.js";
// import PricingPage from "views/Pages/PricingPage.js";
// import RTLSupport from "views/Pages/RTLSupport.js";
// import ReactTables from "views/Tables/ReactTables.js";
// import RegisterPage from "views/Pages/RegisterPage.js";
// import RegularForms from "views/Forms/RegularForms.js";
// import RegularTables from "views/Tables/RegularTables.js";
// import SweetAlert from "views/Components/SweetAlert.js";
// import TimelinePage from "views/Pages/Timeline.js";
// import Typography from "views/Components/Typography.js";
// import UserProfile from "views/Pages/UserProfile.js";
// import ValidationForms from "views/Forms/ValidationForms.js";
// import VectorMap from "views/Maps/VectorMap.js";
// import Widgets from "views/Widgets/Widgets.js";
// import Wizard from "views/Forms/Wizard.js";

import MyBookShelf from "./modules/private/pages/MyBookShelf";
import MyRequests from "./modules/private/pages/MyRequests";
import MyTransactions from "./modules/private/pages/MyTransactions";
import BrowseAllBooks from "./modules/private/pages/HomePage";
import MyProfile from "./modules/private/pages/MyProfile";
import FAQ from "./modules/private/pages/faq-page";
import Notifications from "./modules/private/pages/notifications-page";
import Logout from "./modules/private/pages/logout";

// Icons
import HomeIcon from '@material-ui/icons/Home';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';

var dashRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: HomeIcon,
    component: BrowseAllBooks,
  },
  {
    path: "/my-book-shelf",
    name: "My Book Shelf",
    icon: CollectionsBookmarkIcon,
    component: MyBookShelf,
  },
  {
    path: "/my-requests",
    name: "Requests & Chat",
    icon: SwapHorizontalCircleIcon,
    component: MyRequests,
  },
  {
    path: "/my-transactions",
    name: "My Transactions",
    icon: ReceiptIcon,
    component: MyTransactions,
  },
  {
    path: "/my-profile",
    name: "My Profile",
    icon: AccountCircleIcon,
    component: MyProfile,
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: HelpIcon,
    component: FAQ,
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: NotificationsIcon,
    component: Notifications,
  },
  // {
  //   path: "/logout",
  //   name: "Logout",
  //   icon: PowerSettingsNewIcon,
  //   component: Logout,
  // },
];
export default dashRoutes;
