import React, { Component } from "react";
import CustomDateRangePicker from "../../general/components/dateRangePicker";
import moment from "moment";
// material-ui components
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import Box from '@material-ui/core/Box';
import Card from "../../general/components/theme-components/Card/Card";
import CardBody from "../../general/components/theme-components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "@material-ui/core";

import ViewDetailModal from "./ViewDetailModal";
import { getRequestsToUser, updateRequest } from "../../../http/http-calls";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { ToastsStore } from "react-toasts";
import Paginate from "../../general/components/pagination";

class MyRequestsRecieved extends Component {

  state = {
    viewDetailModal: {
      isOpen: false,
      data: null,
    },
    search: "",
    filters: {
      interest: "All",
      dateRange: {
        startDate: null,
        endDate: null,
        focusedInput: null,
        startDateId: "startDate",
        endDateId: "endDate"
      },
      status: "None"
    },
    requests: [],
    requestStatus: "Pending",
    totalPages: "",
    currentPage: 1,
    totalCount: ""
  }

  componentDidMount() {
    this._getRequestsRecieved();
  }

  _getRequestsRecieved = () => {
    this.props.showLoader();
    const { search, filters } = this.state;
    const payload = {
      filters: {},
      pageNumber: this.state.currentPage
    }
    if (search !== undefined && search !== "") {
      payload.search = search
    }
    if (filters.interest !== undefined && filters.interest !== "" && filters.interest !== "All") {
      payload.filters.interest = filters.interest
    }
    if (filters.status !== undefined && filters.status !== "" && filters.status !== "None") {
      payload.filters.status = filters.status
    }
    if (filters.dateRange.startDate && filters.dateRange.endDate) {
      payload.filters.dateRange = {
        from: moment(filters.dateRange.startDate).format('DD/MM/YYYY'),
        to: moment(filters.dateRange.endDate).format('DD/MM/YYYY')
      }
    }
    getRequestsToUser(payload).then((resp) => {
      // console.log("List of Request recieved here :- ", resp);
      this.setState({
        requests: resp.requests,
        currentPage: resp.currentPage,
        totalPages: resp.totalPages,
        totalCount: resp.totalCount
      });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Failed to load requests recieved :- " + err.reason);
    });
  }

  _onToggleViewDetailModal = (data) => {
    let { viewDetailModal } = JSON.parse(JSON.stringify(this.state));
    viewDetailModal.isOpen = !viewDetailModal.isOpen;
    if (data !== undefined) {
      viewDetailModal.data = data;
    } else {
      viewDetailModal.data = null;
    }
    this.setState({
      viewDetailModal,
    });
  }

  _handleOnChange = (field, value) => {
    let { search, filters } = this.state;
    if (field === "Search") {
      search = value;
      this.setState({ search }, () => {
        this._getRequestsRecieved()
      });
    } else {
      if (value !== undefined) {
        filters[field] = value;
      } else {
        filters[field] = "";
      }
      this.setState({ filters }, () => {
        this._getRequestsRecieved()
      });
    }
  }

  _onDatesChange = (startDate, endDate) => {
    const { filters } = this.state;
    filters.dateRange.startDate = startDate;
    filters.dateRange.endDate = endDate;
    this.setState({ filters });
    if (filters.dateRange.startDate && filters.dateRange.endDate) {
      this._getRequestsRecieved();
    }
  };

  _onFocusChange = input => {
    const { filters } = this.state;
    filters.dateRange.focusedInput = input;
    this.setState({ filters });
  };

  _clearSuggestions = () => {
    this.setState({
      search: "",
      filters: {
        interest: "All",
        dateRange: {
          startDate: null,
          endDate: null,
          focusedInput: null,
          startDateId: "startDate",
          endDateId: "endDate"
        },
        status: "None"
      }
    }, () => {
      this._getRequestsRecieved();
    });
  }

  _updateRequestStatus = (value, id) => {
    if (value !== "Pending") {
      this.props.showLoader();
      updateRequest({ status: value }, id).then((resp) => {
        // console.log("Update Request response here - ", resp);
        this._getRequestsRecieved();
        this.props.hideLoader();
        ToastsStore.success("Status updated succesfully");
      }).catch((err) => {
        this.props.hideLoader();
        ToastsStore.error("Status updation failed - " + err.reason)
      });
    }
  }

  _handleAction = (status, id) => {
    const { requestStatus } = this.state;
    if (status === "Requested") {
      return (
        <TableCell key={id} className="action-received">
          <Box component="div">
            <Button className="btnAccept" onClick={() => this._updateRequestStatus("Accepted", id)}>
              Accept
            </Button>
            <Button className="btnDelete" onClick={() => this._updateRequestStatus("Rejected", id)}>
              Delete
            </Button>
          </Box>
        </TableCell>
      )
    } else if (status === "Completed" || status === "Rejected") {
      return (
        <TableCell key={id}>{status}</TableCell>
      )
    } else {
      return (
        <TableCell key={id} className="action-received">
          <FormControl variant="outlined" className="m-0">
            <InputLabel id={`demo-simple-select-autowidth-label4 ${id}`}>Status</InputLabel>
            <Select
              labelId={`demo-simple-select-autowidth-label4 ${id}`}
              label="Book"
              value={requestStatus}
              onChange={(e) => this._updateRequestStatus(e.target.value, id)}
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      )
    }
  }

  _showRequests = () => {
    const { requests } = this.state;
    return requests && requests.map((request) => {
      return (
        <>
          <TableRow key={request._id}>
            <TableCell>{request._from.name.full}</TableCell>
            <TableCell>{request._requestedBook.title}</TableCell>
            <TableCell>{request.isSwap ? "Swap" : "Sell"}</TableCell>
            <TableCell
              onClick={() => request.isSwap ? this._onToggleViewDetailModal(request._swapBook?._id)
                : console.log()
              }
            ><Link className="bookOffered">{request.isSwap ? request._swapBook?.title : "-"}</Link>
            </TableCell>
            <TableCell>{moment(request.createdAt).format("DD MMM YYYY")}</TableCell>
            <TableCell><i class='fas fa-comment text-theme' style={{ padding: '0 3px' }}
              onClick={() => this.props.startChat(request._id)}
            ></i></TableCell>
            {request.status && this._handleAction(request.status, request._id)}
          </TableRow>
        </>
      )
    });
  }

  _paginatePage = (pageNumber) => {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this._getRequestsRecieved();
    });
  };

  _getFirstElement = () => {
    return this.state.requests.length > 0 ? (this.state.currentPage * 10 - 9) : 0;
  }

  _getLastElement = () => {
    return this.state.requests.length === 10 ? this.state.currentPage * 10 : this.state.totalCount;
  }

  render() {
    const { requests, filters, search, totalCount } = this.state;
    return (
      <div>
        <Box component="div"
          className="ModalFormRow my-requests-form customFilterWrap"
        >
          <Box component="div" className="searchBook">
            <form autoComplete="off">
              <label>
                Book Name
              </label>
              <Box component="div" className="search-input">
                <TextField
                  type="text"
                  autoComplete="current-text"
                  variant="outlined"
                  value={search}
                  onChange={(e) => this._handleOnChange("Search", e.target.value)}
                />
              </Box>
            </form>
          </Box>

          <Box component="div" className="searchBook customWidth">
            <form autoComplete="off">
              <Box component="div" className="filter-input">
                <FormControl variant="outlined" className="m-0">
                  <label>Interest</label>
                  <Select
                    labelId="demo-simple-select-autowidth-label2"
                    value={filters.interest}
                    onChange={(e) => this._handleOnChange("interest", e.target.value)}
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value="Swap">Swap</MenuItem>
                    <MenuItem value="Sell">Sell</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </form>
          </Box>

          <Box component="div" className="searchBook customWidth">
            <form autoComplete="off">
              <Box component="div" className="filter-input">
                <FormControl variant="outlined" className="m-0">
                  <label>Status</label>
                  <Select
                    labelId="demo-simple-select-autowidth-label2"
                    value={filters.status}
                    onChange={(e) => this._handleOnChange("status", e.target.value)}
                  >
                    <MenuItem value="None">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </form>
          </Box>

          <Box component="div" className="searchBook">
            <form autoComplete="off">
              <FormControl variant="outlined" className="m-0">
                <label>Date Range</label>
                <CustomDateRangePicker
                  dateRange={this.state.filters.dateRange}
                  onDatesChange={this._onDatesChange}
                  onFocusChange={this._onFocusChange}
                />
              </FormControl>
            </form>
          </Box>
        </Box>

        {/* ============ Clear All Button =============*/}
        <Box component="div" className="clear-all clear-all-custom" onClick={() => this._clearSuggestions()}>
          Clear All
          <i className="fa fa-refresh"></i>
        </Box>

        {/* ================== Table ===============*/}
        <Card className="bookShellCard bookShellModal bookTableCard">
          <CardBody className="w-100 h-100">
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Requested By</TableCell>
                    <TableCell>Book</TableCell>
                    <TableCell>Interested in</TableCell>
                    <TableCell>Book Offered</TableCell>
                    <TableCell>Requested On</TableCell>
                    <TableCell>Chat</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requests.length ? this._showRequests()
                    :
                    <TableRow>
                      <TableCell align="center" colspan="6">
                        No request to show
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>

        {/* ============== Load More =============*/}
        <Box component="div" className="paginationWrap">
          <span className="poweredBy">
            Showing  <b>{this._getFirstElement()}</b> to <b>{this._getLastElement()}</b> out of <b>{totalCount}</b>
          </span>
          <div>
            <Paginate
              totalPages={this.state.totalPages}
              currentPage={this.state.currentPage}
              paginate={this._paginatePage}
            ></Paginate>
          </div>
        </Box>
        <ViewDetailModal
          isOpen={this.state.viewDetailModal.isOpen}
          creatorData={this.state.viewDetailModal.data}
          toggle={() => this._onToggleViewDetailModal()}
          toUserBookShelf={(id) => this.props.toUserBookShelf(id)}
          reload={() => this._getRequestsRecieved()}
        ></ViewDetailModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRequestsRecieved);