import React, { Component } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import PublicRoute from "./modules/general/components/public-route";
import { persistor, store } from "./redux/store";
import Login from "./modules/public/pages/login";
import Forgot from "./modules/public/pages/forgot";
import Register from "./modules/public/pages/register";
// import ForgotPassword from "./modules/public/pages/forgot-password";
// import SignUp from "./modules/public/pages/signup";
import Layout from "./modules/private/containers/layout";
import FullPageLoader from "./modules/general/container/full-page-loader";

class App extends Component {
  render() {
    // console.log = () => {};
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <FullPageLoader />
            <ToastsContainer
              store={ToastsStore}
              position={ToastsContainerPosition.BOTTOM_RIGHT}
            />
            <Switch>
              <PublicRoute
                path="/login"
                component={Login}
                redirectRoute={"/home"}
              />
              <PublicRoute
                path="/forgot-password"
                component={Forgot}
                redirectRoute={"/home"}
              />
              <PublicRoute
                path="/register"
                component={Register}
                redirectRoute={"/home"}
              />
              <Route path="/" component={Layout} />

              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
