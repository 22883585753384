import React, { Component } from "react";

// material-ui components
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import Box from '@material-ui/core/Box';
import Card from "../../general/components/theme-components/Card/Card";
import CardBody from "../../general/components/theme-components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MenuItem, Select } from "@material-ui/core";

import { getProfileDetails, checkUsername, updateProfileDetails, uploadCloudinary } from "../../../http/http-calls";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { ToastsStore } from "react-toasts";

class MyProfile extends Component {

  state = {
    user: {
      name: "",
      email: "",
      phone: "",
      username: "",
      occupation: "",
      interestedCategories: [],
      street: "",
      area: "",
      landmark: "",
      city: "",
      state: "",
      pincode: "",
      profilePicUrl: ""
    },
    isDirty: {
      name: false,
      email: false,
      phone: false,
      username: false,
      occupation: false,
      pincode: false,
    },
    errors: {},
    userBackUp: {},
    preferenceOptions: []
  }

  componentDidMount() {
    this._getProfile();
  }

  _getProfile = () => {
    this.props.showLoader();
    getProfileDetails().then((resp) => {
      // console.log("My Profile here :- ", resp);
      this.setState({
        user: {
          name: resp.user?.name?.full ? resp.user.name.full : "",
          email: resp.user.email ? resp.user.email : "",
          phone: resp.user.phone ? resp.user.phone : "",
          username: resp.user.username ? resp.user.username : "",
          occupation: resp.user.occupation ? resp.user.occupation : "",
          interestedCategories: resp.user.interestedCategories?.length ? resp.user.interestedCategories : [],
          street: resp.user.address?.street ? resp.user.address.street : "",
          area: resp.user.address.area ? resp.user.address.area : "",
          landmark: resp.user.address.landmark ? resp.user.address.landmark : "",
          city: resp.user.address.city ? resp.user.address.city : "",
          state: resp.user.address.state ? resp.user.address.state : "",
          pincode: resp.user.address.pincode ? resp.user.address.pincode : "",
          profilePicUrl: resp.user.profilePicUrl ? resp.user.profilePicUrl : ""
        },
        preferenceOptions: resp.setting?.categories.length ? resp.setting.categories : [],
        userBackUp: resp.user
      });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Failed to load profile :- " + err.reason);
    })
  }

  _handleOnChange = (field, value) => {
    const { user, isDirty } = this.state;
    user[field] = value;
    if (isDirty[field] === false) {
      isDirty[field] = true;
    }
    this.setState({ user, isDirty }, () => {
      this._validateFields();
    });
  }

  _handlePreferences = (event, value) => {
    const { user } = this.state;
    user.interestedCategories = value;
    this.setState({ user });
  }

  _validateFields = () => {
    const { user, isDirty, errors, userBackUp } = this.state;
    Object.keys(user).forEach((each) => {
      if (each === "name" && isDirty.name) {
        if (!user.name.trim().length) {
          errors[each] = "*Required";
        } else if (user.name.trim().length
          && !user.name.match(/^[a-zA-Z ]*$/)
        ) {
          errors[each] = "Invalid name format";
        } else if (user.name.trim().length
          && user.name.match(/^[a-zA-Z ]*$/)
          && user.name.length < 3
        ) {
          errors[each] = "Name must be of 3 or more characters";
        } else {
          delete errors[each];
          isDirty.name = false;
        }
      } else if (each === "phone" && isDirty.phone) {
        if (!user.phone.trim().length) {
          errors[each] = "*Required";
        } else if (user.phone.trim().length
          && !user.phone.match(/^[0-9]{10}$/)
        ) {
          errors[each] = "Enter valid phone number";
        } else {
          delete errors[each];
          isDirty.phone = false;
        }
      } else if (each === "email" && isDirty.email) {
        if (!user.email.trim().length) {
          errors[each] = "*Required";
        } else if (user.email.trim().length
          && !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(user.email)
        ) {
          errors[each] = "Invalid Email";
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      } else if (each === "occupation" && isDirty.occupation) {
        if (!user.occupation.trim().length) {
          errors[each] = "*Required";
        } else {
          delete errors[each];
          isDirty.occupation = false;
        }
      } else if (each === "username" && isDirty.username) {
        if (!user.username.trim().length) {
          errors[each] = "*Required";
        } else if (user.username.trim().length
          && user.username !== userBackUp.username
        ) {
          let obj = {
            username: user.username
          }
          this._checkUnique(obj);
        } else {
          delete errors[each];
          isDirty.username = false;
        }
      } else if (each === "pincode" && isDirty.pincode) {
        if (!user.pincode.toString().trim().length) {
          errors[each] = "*Required";
        } else if (user.pincode.toString().trim().length
          && !user.pincode.toString().match(/^[0-9]{6}$/)) {
          errors[each] = "Enter valid pincode";
        } else {
          delete errors[each];
          isDirty.pincode = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _checkUnique = (username) => {
    let { errors } = this.state;
    checkUsername(username).then((resp) => {
      if (resp.isAvailable) {
        delete errors.username;
      } else {
        errors.username = "Enter a unique username"
      }
      this.setState({ errors });
    });
  }

  _updateProfile = (e) => {
    e.preventDefault();
    const { user, userBackUp } = this.state;
    let isDirty = {
      name: true,
      email: true,
      phone: true,
      occupation: true,
      pincode: true,
      username: true
    };
    this.setState({ isDirty }, () => {
      const errors = this._validateFields();
      if (!errors) {
        let payload = {
          address: {}
        };
        Object.keys(user).forEach((each) => {
          if (each === "username") {
            if (userBackUp.username !== user.username) {
              payload.username = user.username;
            }
          } else if (each === "phone") {
            if (userBackUp.phone !== user.phone) {
              payload.phone = user.phone;
            }
          } else if (each === "email") {
            if (userBackUp.email !== user.email) {
              payload.email = user.email;
            }
          } else if (each === "name") {
            if (userBackUp.name.full !== user.name) {
              payload.name = {
                first: user.name.split(" ")[0],
                last: user.name.split(" ")[1] ? user.name.split(" ")[1] : ""
              };
            }
          } else if (each === "occupation") {
            if (userBackUp.occupation !== user.occupation) {
              payload.occupation = user.occupation;
            }
          } else if (each === "profilePicUrl") {
            if (user.profilePicUrl.trim().length
              && (userBackUp.profilePicUrl === undefined
                || userBackUp.profilePicUrl !== user.profilePicUrl
              )) {
              payload.profilePicUrl = user.profilePicUrl;
            }
          } else if (each === "street") {
            if (user.street.trim().length
              && (userBackUp.address.street === undefined || userBackUp.address.street !== user.street)) {
              payload.address.street = user.street;
            }
          } else if (each === "state") {
            if (user.state.trim().length
              && (userBackUp.address.state === undefined || userBackUp.address.state !== user.state)) {
              payload.address.state = user.state;
            }
          } else if (each === "area") {
            if (user.area.trim().length
              && (userBackUp.address.area === undefined || userBackUp.address.area !== user.area)) {
              payload.address.area = user.area;
            }
          } else if (each === "landmark") {
            if (user.landmark.trim().length
              && (userBackUp.address.landmark === undefined || userBackUp.address.landmark !== user.landmark)) {
              payload.address.landmark = user.landmark;
            }
          } else if (each === "city") {
            if (user.city.trim().length
              && (userBackUp.address.city === undefined || userBackUp.address.city !== user.city)) {
              payload.address.city = user.city;
            }
          } else if (each === "pincode") {
            if (user.pincode.toString().trim().length
              && (userBackUp.address.pincode === undefined || userBackUp.address.pincode.toString() !== user.pincode.toString())) {
              payload.address.pincode = user.pincode;
            }
          } else if (each === "interestedCategories") {
            if (user.interestedCategories.length > 0
              && (userBackUp.interestedCategories === undefined || userBackUp.interestedCategories.toString() !== user.interestedCategories.toString())) {
              payload.interestedCategories = user.interestedCategories;
            }
          }
        });
        this.props.showLoader();
        updateProfileDetails(payload).then((resp) => {
          // console.log("Update profile response here :- ", resp);
          ToastsStore.success("Profile updated successfully");
          this._getProfile();
          this.props.hideLoader();
        }).catch((err) => {
          this.props.hideLoader();
          ToastsStore.error("Failed to update profile :- " + err.reason);
        });
      }
    })
  }

  _handleFileChange = (e) => {
    const { user } = this.state;
    const imageData = e.target.files[0];
    const fd = new FormData();
    fd.append("file", imageData);
    this.props.showLoader();
    uploadCloudinary(fd).then((resp) => {
      user.profilePicUrl = resp.url;
      this.setState({ user });
      this.props.hideLoader();
    }).catch((err) => {
      this.props.hideLoader();
      ToastsStore.error("Failed to upload image :- " + err.reason);
    });
  }

  render() {
    const { user, errors, preferenceOptions } = this.state;
    return (
      <>
        <div className="content">
          <div className="pgTitleWrap justify-content-between">
            <h4 className="pageTitle">My Profile</h4>
          </div>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className="ModalFormRow"
          >
            <GridItem xs={12} sm={11}>
              <Card className="bookShellCard my-profile">
                <CardBody className="w-100 h-100">
                  <Box component="div" className="profile-imgbox">
                    <InputLabel className="uploadProfile">
                      <Input
                        type="file"
                        style={{ display: "none" }}
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={(e) => this._handleFileChange(e)}
                      />
                      {user?.profilePicUrl && user.profilePicUrl !== "" ? <img
                        src={user.profilePicUrl}
                        alt="Profile Img"
                      /> : <img
                        src="https://cdn.pixabay.com/photo/2017/06/13/12/53/profile-2398782_1280.png"
                        alt="Profile Img"
                      />}
                      <i className="fa fa-pencil"></i>
                    </InputLabel>
                    <h1 className="text-theme fw-600">Profile Picture</h1>
                  </Box>

                  {/* ========== Form ======== */}
                  <form noValidate autoComplete="off" className="my-profile-form">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className="ModalFormRow"
                    >
                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>Name</InputLabel>
                          <Input type="text" variant="outlined"
                            placeholder="Enter"
                            value={user.name}
                            onChange={(e) => this._handleOnChange("name", e.target.value)}
                          />
                          {errors && (
                            <small
                              className='validation-error'
                            >
                              {errors.name}
                            </small>
                          )}
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>Mobile</InputLabel>
                          <Input type="text" variant="outlined"
                            placeholder="Enter"
                            value={user.phone}
                            onChange={(e) => this._handleOnChange("phone", e.target.value)}
                          />
                          {errors && (
                            <small
                              className='validation-error'
                            >
                              {errors.phone}
                            </small>
                          )}
                        </FormControl>
                      </GridItem>

                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className="ModalFormRow"
                    >

                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>Username</InputLabel>
                          <Input type="text" variant="outlined"
                            placeholder="Enter"
                            value={user.username}
                            onChange={(e) => this._handleOnChange("username", e.target.value)}
                          />
                          {errors && (
                            <small
                              className='validation-error'
                            >
                              {errors.username}
                            </small>
                          )}
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>Occupation</InputLabel>
                          <Input type="text" variant="outlined"
                            placeholder="Enter"
                            value={user.occupation}
                            onChange={(e) => this._handleOnChange("occupation", e.target.value)}
                          />
                          {errors && (
                            <small
                              className='validation-error'
                            >
                              {errors.occupation}
                            </small>
                          )}
                        </FormControl>
                      </GridItem>
                    </Grid>


                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className="ModalFormRow"
                    >

                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>Email</InputLabel>
                          <Input type="email" variant="outlined"
                            placeholder="Enter"
                            value={user.email}
                            onChange={(e) => this._handleOnChange("email", e.target.value)}
                          />
                          {errors && (
                            <small
                              className='validation-error'
                            >
                              {errors.email}
                            </small>
                          )}
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} className="customTag">
                        <InputLabel>Interested Categories</InputLabel>
                        <FormControl>
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            // style={{ width: "600px" }}
                            options={preferenceOptions}
                            getOptionLabel={(option) => option}
                            value={user.interestedCategories}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                              // label="Interested Categories"
                              // placeholder="Interested Categories"
                              />
                            )}
                            onChange={this._handlePreferences}
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className="ModalFormRow"
                    >

                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>Street</InputLabel>
                          <Input type="text" variant="outlined"
                            placeholder="Enter"
                            value={user.street}
                            onChange={(e) => this._handleOnChange("street", e.target.value)}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>Area</InputLabel>
                          <Input type="text" variant="outlined"
                            placeholder="Enter"
                            value={user.area}
                            onChange={(e) => this._handleOnChange("area", e.target.value)}
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className="ModalFormRow"
                    >

                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>Landmark</InputLabel>
                          <Input type="text" variant="outlined"
                            placeholder="Enter"
                            value={user.landmark}
                            onChange={(e) => this._handleOnChange("landmark", e.target.value)}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>City</InputLabel>
                          <Input type="text" variant="outlined"
                            placeholder="Enter"
                            value={user.city}
                            onChange={(e) => this._handleOnChange("city", e.target.value)}
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className="ModalFormRow"
                    >

                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>Pincode</InputLabel>
                          <Input type="text" variant="outlined"
                            placeholder="Enter"
                            value={user.pincode}
                            onChange={(e) => this._handleOnChange("pincode", e.target.value)}
                          />
                          {errors && (
                            <small
                              className='validation-error'
                            >
                              {errors.pincode}
                            </small>
                          )}
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={6} className="flex-center">
                        <FormControl>
                          <InputLabel>State</InputLabel>
                          <Select
                            labelId="demo-simple-select-autowidth-label2"
                            label="All Type"
                            value={user.state}
                            onChange={(e) => this._handleOnChange("state", e.target.value)}
                          >
                            <MenuItem>
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="Andhra Pradesh">Andhra Pradesh</MenuItem>
                            <MenuItem value="Arunachal Pradesh">Arunachal Pradesh</MenuItem>
                            <MenuItem value="Assam">Assam</MenuItem>
                            <MenuItem value="Bihar">Bihar</MenuItem>
                            <MenuItem value="Chhattisgarh">Chhattisgarh</MenuItem>
                            <MenuItem value="Goa">Goa</MenuItem>
                            <MenuItem value="Gujarat">Gujarat</MenuItem>
                            <MenuItem value="Haryana">Haryana</MenuItem>
                            <MenuItem value="Himachal Pradesh">Himachal Pradesh</MenuItem>
                            <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                            <MenuItem value="Karnataka">Karnataka</MenuItem>
                            <MenuItem value="Kerala">Kerala</MenuItem>
                            <MenuItem value="Maharashtra">Maharashtra</MenuItem>
                            <MenuItem value="Manipur">Manipur</MenuItem>
                            <MenuItem value="Meghalaya">Meghalaya</MenuItem>
                            <MenuItem value="Mizoram">Mizoram</MenuItem>
                            <MenuItem value="Nagaland">Nagaland</MenuItem>
                            <MenuItem value="Odisha">Odisha</MenuItem>
                            <MenuItem value="Punjab">Punjab</MenuItem>
                            <MenuItem value="Rajasthan">Rajasthan</MenuItem>
                            <MenuItem value="Sikkim">Sikkim</MenuItem>
                            <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
                            <MenuItem value="Telangana">Telangana</MenuItem>
                            <MenuItem value="Tripura">Tripura</MenuItem>
                            <MenuItem value="Uttar Pradesh">Uttar Pradesh</MenuItem>
                            <MenuItem value="Uttarakhand">Uttarakhand</MenuItem>
                            <MenuItem value="West Bengal">West Bengal</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </Grid>

                    <Box component="div" className="submitBtn">
                      <Button className="bg-theme fs-18" round
                        onClick={(e) => this._updateProfile(e)}
                      > SAVE </Button>
                    </Box>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderData) => dispatch(showLoader(loaderData)),
    hideLoader: (loaderData) => dispatch(hideLoader(loaderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);