import React, { Component } from "react";
import Faye from "faye";
import moment from "moment";
import config from "../../../config";
// material-ui components
import SendIcon from '@material-ui/icons/Send';
import Button from "../../general/components/theme-components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Box from '@material-ui/core/Box';
import Card from "../../general/components/theme-components/Card/Card";
import CardBody from "../../general/components/theme-components/Card/CardBody";
import Grid from "../../general/components/theme-components/Grid/GridContainer";
import GridItem from "../../general/components/theme-components/Grid/GridItem";
import { getChatThread, createThread, getChatThreadById, getProfileDetails } from "../../../http/http-calls";
import { showToast, deepClone } from "../../../helper-methods";

let client = {};
let subscription = null;

export class Chat extends Component {
  state = {
    newMessage: "",
    chatThread: {
      _messages: [],
    },
    participants: [],
    chatThreadId: "",
    userData: {},
    anotherUserData: {}
  };

  componentDidMount() {
    let { userData, anotherUserData } = this.state;
    if (this.props) {
      // console.log('this.props :>> ', this.props);
      if (this.props.request) {
        getProfileDetails().then((resp) => {
          if (resp.user._id === this.props.request._from._id) {
            userData = this.props.request._from;
            anotherUserData = this.props.request._to;
          } else {
            anotherUserData = this.props.request._from;
            userData = this.props.request._to;
          }
          this.setState({ userData, anotherUserData }, () => {
            this._getThreadWithMessages(this.props.request.id);
            // console.log("Users here ", this.state.userData, this.state.anotherUserData)
          });
        }).catch((err) => {
          console.log("error :>> ", err);
        });
      }
    }
  }

  componentWillUnmount() {
    if (client != null) {
      console.log("componentWillUnmount chat :>> ");
      client = null;
    }
    if (subscription) {
      subscription.cancel();
    }
  }

  _configureChatClient = () => {
    const { chatThreadId } = this.state;
    client = new Faye.Client(config.CHAT_URL);
    // console.log("client :>> ", client);
    this._startChat(chatThreadId);
  }

  _getThreadWithMessages = async (requestId) => {
    try {
      let response = await getChatThread(requestId);
      this.setState({
        chatThread: {
          _messages: response.messages
        },
        participants: response.thread._participants,
        chatThreadId: response.thread._id
      }, () => {
        let objDiv = document.getElementById("chat-panel");
        objDiv.scrollTop = objDiv.scrollHeight;
        this._configureChatClient();
      });
    } catch (err) {
      console.log("error :>> ", err);
      if (err.reason === "No thread found!!!") {
        this._createChatThread();
      }
    }
  }

  _createChatThread = () => {
    const { request } = deepClone(this.props);
    const payload = {
      request: request.id,
      participants: [request._from._id, request._to._id]
    };
    createThread(payload).then((resp) => {
      // console.log("response of new chat thread :>> ", resp.thread)
      this.setState({
        chatThreadId: resp.thread._id
      }, () => {
        let objDiv = document.getElementById("chat-panel");
        objDiv.scrollTop = objDiv.scrollHeight;
        this._getThreadWithMessages(resp.thread._request);
      });
    }).catch((err) => {
      console.log("Error here :>> ", err);
    });
  }

  _getChatHistory = async (currentChatThreadId) => {
    try {
      let response = await getChatThreadById(currentChatThreadId);
      // console.log("Chat history response :- ", response);
      this.setState({
        chatThread: {
          _messages: response.messages
        },
        participants: response.thread._participants,
        chatThreadId: response.thread._id,

      }, () => {
        let objDiv = document.getElementById("chat-panel");
        objDiv.scrollTop = objDiv.scrollHeight;
      });
    } catch (err) {
      console.log("error :>> ", err);
    }
  }

  _startChat = (currentChartThreadId) => {
    if (subscription) subscription.cancel();
    subscription = client.subscribe(
      `/chat/${currentChartThreadId}`,
      (newMessage) => {
        // console.log("New Message: ", newMessage, currentChartThreadId);
        const { chatThread, participants, anotherUserData } = this.state;
        chatThread._messages.push({
          _id: new Date().getTime(),
          what: newMessage.what,
          when: new Date(),
          by: {
            _id:
              participants.filter(
                (e) => e !== newMessage.forWhom
              )[0],
            profilePicUrl: anotherUserData._id === newMessage.by ? anotherUserData.profilePicUrl : ''
          },
          forWhom: {
            _id: newMessage.forWhom,
          }
        });
        this.setState({ chatThread }, () => {
          // console.log("here :>> ", chatThread._messages.length);
          let objDiv = document.getElementById("chat-panel");
          objDiv.scrollTop = objDiv.scrollHeight;
        });
        this._getChatHistory(currentChartThreadId);
        // this.forceUpdate();
      }
    );
  };

  _publishMessage = () => {
    const { newMessage, userData, participants, chatThreadId } = this.state;
    // console.log("Participants here :>> ", participants)
    const dataToPublish = {
      what:
        newMessage || ' ',
      by: userData._id,
      forWhom: participants.filter(
        (e) => e._id !== userData._id
      )[0]._id,
      whoami: userData.name?.full || "User",
    };
    // console.log("object :>> ", dataToPublish);
    client
      .publish(`/chat/${chatThreadId}`, dataToPublish)
      .then(() => {
        // console.log("Message Published!");
        let { newMessage } = deepClone(this.state);
        newMessage = "";
        this.setState({
          newMessage
        }, () => {
          this._getChatHistory(this.state.chatThreadId);
        });
      })
      .catch((err) => console.log(err));
  };

  _handleOnMessageTextChange = (text) => {
    let { newMessage } = this.state;
    newMessage = text;
    this.setState({ newMessage });
  };

  _sendMessage = async (e) => {
    e.preventDefault();
    // console.log('here :>> ',);
    const { newMessage } = deepClone(this.state);
    if (!newMessage.trim().length) {
      return;
    } else {
      // console.log('only text :>> ');
      this._publishMessage();
    }
  };

  render() {
    const { newMessage, chatThread, userData, anotherUserData } = deepClone(this.state);

    return (
      <>
        <div className="content chat-page">
          <div className="pgTitleWrap" style={{ justifyContent: 'flex-start' }}>
            <Box component="div" className="backBtn">
              <span className="fa fa-chevron-left"
                onClick={() => this.props.toggle()}
              />
            </Box>
            <h4 className="pageTitle">Chat</h4>
          </div>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className="ModalFormRow"
          >
            <GridItem xs={12}>
              <Card className="bookShellCard my-profile chatBoxModal">
                <CardBody className="w-100 h-100">

                  <Box component="div" className="chatBoxContainer">
                    <Box component="div" className="profile-imgbox">
                      <Box component="div" className="uploadProfile">
                        {anotherUserData?.profilePicUrl && anotherUserData.profilePicUrl !== "" ? <img
                          src={anotherUserData.profilePicUrl}
                          alt="Profile Img" />
                          : <img
                            src="https://cdn.pixabay.com/photo/2017/06/13/12/53/profile-2398782_1280.png"
                            alt="Profile Img"
                          />
                        }
                      </Box>
                      <h1 className="fs-18 text-theme fw-600">{anotherUserData.username}</h1>
                    </Box>
                    <Box component="div" className="chatBox" id="chat-panel">
                      {/* =========== Chat-Box =============== */}
                      {chatThread._messages?.length > 0 && chatThread._messages.map((item, index) => (
                        <div key={index}>

                          {userData._id === item.forWhom && (

                            <Grid
                              container
                              direction="row"
                              justify="center"
                              className="ModalFormRow chatInnerBox received"
                            >
                              {/* <GridItem xs={2}>
                                <Box component="div" className="img-box">
                                  <img
                                    src={
                                      anotherUserData.profilePicUrl
                                        ? anotherUserData.profilePicUrl
                                        : "https://cdn.pixabay.com/photo/2017/06/13/12/53/profile-2398782_1280.png"
                                    }
                                    alt="Chat Img"
                                    className="fit-img"
                                  />
                                </Box>
                              </GridItem> */}
                              <GridItem xs={12}>
                                <Box component="div" className="text-box">
                                  {item.what?.trim().length > 0 && <p>{item.what}</p>}
                                  <span className="time">{moment(item.when).format("ddd hh:mm a")}</span>
                                </Box>
                              </GridItem>
                            </Grid>
                          )}
                          {userData._id === item.by && (
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              className="ModalFormRow chatInnerBox sent"
                            >
                              <GridItem xs={12}>
                                <Box component="div" className="text-box">
                                  {item.what?.trim().length > 0 && <p>{item.what}</p>}
                                  <span className="time">{moment(item.when).format("ddd hh:mm a")}</span>
                                </Box>
                              </GridItem>
                            </Grid>
                          )
                          }
                        </div>
                      ))}
                      {chatThread._messages?.length === 0 && (
                        <div style={{ textAlign: "center", width: "inherit" }}><h5>No messages yet, start texting</h5></div>
                      )}
                    </Box>
                  </Box>
                  <Box component="div" className="chatBoxMessage">
                    <form noValidate autoComplete="off" className="chat-form" style={{ marginBottom: 0 }} onSubmit={(e) => this._sendMessage(e)}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className="ModalFormRow my-requests-form"
                      >
                        <GridItem xs={12}>
                          <Box component="div" className="search-input">
                            <TextField
                              label="Write Here"
                              type="text"
                              autoComplete="current-text"
                              variant="outlined"
                              value={newMessage}
                              onChange={(e) => this._handleOnMessageTextChange(e.target.value)}
                            />

                            <SendIcon className="input-icon"
                              type="submit"
                              onClick={(e) => this._sendMessage(e)}
                            />

                          </Box>
                        </GridItem>
                      </Grid>
                    </form>
                  </Box>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
        </div>
      </>
    );
  }
}

export default Chat;